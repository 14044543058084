import React from "react";
import LinearProgress from "@mui/material/LinearProgress";

const Loader = ({ loadingMessage }) => (
  <>
    <div
      style={{
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 2000,
        textAlign: "center",
      }}
    >
      <LinearProgress color="primary" /> <br />
      <span
        className="titleText"
        style={{
          color: loadingMessage ? "white" : "white",
          fontWeight: loadingMessage ? 700 : "normal",
        }}
      >
        {loadingMessage
          ? loadingMessage
          : "Please wait while the request is being processed"}
      </span>
    </div>
    <div
      style={{
        width: "500%",
        height: "500%",
        position: "fixed",
        background: "#777",
        opacity: 0.8,
        zIndex: 1900,
        margin: "-100%",
      }}
    ></div>
  </>
);

export default Loader;
