import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { TextField, Button, Typography, Snackbar, Alert } from "@mui/material";
import "./login.css";
import DGlogo from "../../assets/images/Digital_Green_Primary 1.svg";
import LoginImage from "../../assets/images/Login_image.svg";
import UrlConstants from "../../utils/constants/urlConstants";
import HTTPService from "../../services/HTTPService";
import {
  setRoleLocal,
  setTokenLocal,
  setUserIdLocal,
  getRoleLocal,
  setRoleLocalName,
  flushLocalstorage,
  getTokenLocal,
} from "../../utils/common/common";
import { Navigate, useNavigate } from "react-router-dom";
import { EFarmContext } from "../../Contexts/eFarmContext";
import OtpTimer from "./OtpTimer";

export const Login = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [enterOtp, setEnterOtp] = useState("");
  const [loginError, setLoginError] = useState("");
  const [originalMobileNumber, setOriginalMobileNumber] = useState("");
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [timer, setTimer] = useState(180); // Initial value for the timer
  const { callToast, callLoader } = useContext(EFarmContext);
  const navigate = useNavigate();
  let vertical = "top";
  let horizontal = "right";

  const handleBackButtonClick = () => {
    if (isValidNumber) {
      // Going back from OTP screen, restore the original mobile number
      setMobileNumber(originalMobileNumber);
      setIsValidNumber(false);
      setEnterOtp("");
      setLoginError("");
    } else {
      // If we are on mobile number entry screen, maybe go back to the previous screen or handle as needed
    }
  };
  const handleTimerEnd = () => {
    setShowTimer(false);
    setTimer(180); // Reset timer to initial value
  };
  console.log("check", enterOtp);
  console.log("checkk", mobileNumber);

  const handleLoginEvent = async () => {
    const org_id = localStorage.getItem("org_id");
    console.log("event login is calling");
    let data = {
      source: "web",
      event_type: "login"
    };
    if (org_id) {
      data["org_id"] = org_id;
    }
    try {
      const response = await HTTPService(
        "POST",
        UrlConstants.base_url + UrlConstants.event_login,
        data,
        true,
        true,
        getTokenLocal()
      );
      if (response) {
        console.log(response);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleSubmit = async (action) => {
    console.log("handleSubmit", action);
    setLoginError("");
    setShowTimer(true);
    let data;
    let url;
    let method;

    if (action === "number") {
      url = UrlConstants.base_url + UrlConstants.generate_otp;
      data = { phone: "+91" + mobileNumber };
      method = "POST";
    } else if (action === "otp") {
      url = UrlConstants.base_url + UrlConstants.verify_otp_end_point;
      data = { phone: "+91" + mobileNumber, otp: enterOtp };
      method = "POST";
    }

    callLoader(true);

    try {
      const response = await HTTPService(
        method,
        url,
        data,
        false,
        false,
        false,
        false
      );
      callLoader(false);
      if (action === "number") {
        console.log(response);
        setLoginError("");
        setShowTimer(true);
        setIsValidNumber(true);
        setOriginalMobileNumber(mobileNumber);
        if (response?.status === 201) {
          setShowTimer(true);
        }
      } else if (action === "otp") {
        console.log(action, response);
        if (response?.status === 200) {
          setShowTimer(false);
          localStorage.setItem("email", response?.data?.email);
          localStorage.setItem("first_name", response?.data?.first_name);
          localStorage.setItem("last_name", response?.data?.last_name);
          localStorage.setItem("phone", response?.data?.phone);
          if (response?.data?.org[0]?.id) {
            localStorage.setItem("org_id", response?.data?.org[0].id);
          }
          setTokenLocal(response?.data?.access_token);
          setRoleLocal(response?.data?.role?.role_name);
          setRoleLocalName(response?.data?.role?.role_display_name);
          setUserIdLocal(response?.data?.id);
          let userRole = getRoleLocal();
          console.log("userRole", userRole);

          // Call handleLoginEvent
          await handleLoginEvent();

          if (
            userRole === "dg_user" ||
            userRole === "ri_user" ||
            userRole === "fpc_ceo" ||
            userRole === "fpc_president"
          ) {
            navigate("/FPOManagement");
            callToast("Successfully logged in", "success", true);
          } else {
            setLoginError("Login is not allowed for this role");
            return;
          }
        }
      }
    } catch (e) {
      console.log("e.response.status", e?.response?.status, e);
      callLoader(false);
      if (e?.response?.status === 400) {
        setLoginError(e?.response?.data?.message || "User not registered");
      } else if (e?.response?.status === 403) {
        setLoginError(
          e?.response?.data?.phone ||
            "User suspended. Please try after sometime."
        );
      } else if (e?.response?.status === 401) {
        setLoginError(e?.response?.data?.otp || e?.response?.data?.phone);
      } else if (e?.response?.status === 429) {
        setLoginError(e?.response?.data?.otp || e?.response?.data?.phone);
      } else {
        callToast("Something went wrong!", "error", true);
      }
    }
  };

  useEffect(() => {
    flushLocalstorage(); // Clear cache when login screen mounts
  }, []);

  return (
    <>
      <div style={{ width: "1340px", margin: "0 0" }}>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <div>
              <img className="home-img-class" src={LoginImage} alt="login" />
            </div>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Row className="homepage-logo">
              <img src={DGlogo} alt="DG LOGO" />
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Col className="login-heading mb60">
                  <Typography className="login-head">
                    Welcome to eFarm!
                  </Typography>
                  <Typography className="sub-title">
                    Better manage your Frontline Workers and FPOs
                  </Typography>
                </Col>
                <Col className="login-heading">
                  <Typography className="sub-head">
                    {isValidNumber
                      ? "Enter the OTP"
                      : "Enter your mobile number"}
                  </Typography>
                  <Typography className="sub-title-head">
                    {isValidNumber
                      ? "We have sent you the otp, please check your mobile."
                      : "We will send you otp to verify your mobile number"}
                  </Typography>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    className="login-input-field"
                    required
                    sx={{
                      "& label": {
                        fontSize: "14.6px",
                      },
                    }}
                    id="outlined-required"
                    label={
                      isValidNumber
                        ? "Enter 6 digit OTP"
                        : "Enter mobile number"
                    }
                    value={isValidNumber ? enterOtp : mobileNumber}
                    error={loginError}
                    helperText={loginError ? loginError : null}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const isNumeric = /^[0-9]*$/.test(inputValue);
                      setLoginError("");

                      if (
                        !isValidNumber &&
                        isNumeric &&
                        inputValue.length <= 10
                      ) {
                        setMobileNumber(inputValue);
                        setShowTimer(true);
                      } else {
                        setEnterOtp(
                          isNumeric &&
                            inputValue.length <= 6 &&
                            !isNaN(inputValue)
                            ? inputValue
                            : enterOtp // If the input is not a valid 6-digit number, keep the current value
                        );
                      }
                    }}
                    onKeyDown={(e) => {
                      console.log(e.key);
                      if (e.key === " ") {
                        e.preventDefault();
                      } else if (e.key === "Enter") {
                        if (isValidNumber && enterOtp.length === 6) {
                          handleSubmit("otp");
                        } else if (!isValidNumber && mobileNumber.length === 10) {
                          handleSubmit("number");
                        }
                      }
                    }}
                  />
                  {isValidNumber && (
                    <Row style={{ marginLeft: "33px", marginBottom: "30px" }}>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        {showTimer ? (
                          <OtpTimer
                            duration={timer}
                            onTimerEnd={handleTimerEnd}
                          />
                        ) : (
                          <></>
                        )}
                      </Col>
                      <Col
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        style={{ paddingLeft: "115px" }}
                      >
                        <Button
                          disabled={showTimer ? true : false}
                          onClick={() => handleSubmit("number")}
                          className="resendButton"
                        >
                          Resend OTP
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Col>
              <Row style={{ marginLeft: "30px" }}>
                <Col xs={6} sm={6} md={6} lg={6} style={{ marginTop: "55px" }}>
                  {isValidNumber ? (
                    <Button
                      // variant="outlined"
                      type="submit"
                      className={"primaryButton"}
                      onClick={handleBackButtonClick}
                    >
                      Back
                    </Button>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ paddingLeft: "44px", marginTop: "55px" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={
                      isValidNumber
                        ? enterOtp.length !== 6
                        : mobileNumber.length !== 10
                    }
                    className={"Send-Otp"}
                    onClick={() => {
                      isValidNumber
                        ? handleSubmit("otp")
                        : handleSubmit("number");
                    }}
                  >
                    {isValidNumber ? "Submit" : "Send OTP"}
                  </Button>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};
