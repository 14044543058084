
import React from "react";
import { useState } from "react";

export const EFarmContext = React.createContext();

const EFarmProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  //Available options for toast type error, warning, info & success
  const [toastDetail, setToastDetail] = useState({
    status: false,
    type: "",
    message: "",
  });

  function callLoader(condtion) {
    setIsLoading(condtion);
  }

  function callToast(message, type, action) {
    setToastDetail({ type: type, message: message, status: action });
  }


  const values = {
    callLoader,
    isLoading,
    toastDetail,
    callToast,
  };
  return (
    <EFarmContext.Provider value={values}>
      {children}
    </EFarmContext.Provider>
  );
};

export default EFarmProvider;
