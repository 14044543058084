import React from "react";
import "./App.css";
import { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Toast from "./utils/Toast/Toast";
import Loader from "./components/Loader/Loader";
import { EFarmContext } from "./Contexts/eFarmContext";
import UserRoutes from "./routes/Routes";

function App() {
  const { isLoading, toastDetail,} =
  useContext(EFarmContext);
  return (
    <>
     {isLoading ? <Loader /> : ""}
      {toastDetail.status ? (
        <Toast message={toastDetail.message} type={toastDetail.type} />
      ) : (
        ""
      )}
      <Router>
        <Routes>
          <Route path="*" element={<UserRoutes />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
