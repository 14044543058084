import React, { useContext } from "react";
import axios from "axios";
import { useState } from "react";
import UrlConstants from "../../utils/constants/urlConstants";
import { getTokenLocal } from "../../utils/common/common";
import FileUploaderMain from "../uploadFile/fileUploaderMain";
import LocalStyle from "./BulkUpload.module.css";
import { Row, Col } from "react-bootstrap";
import {
  Button,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { EFarmContext } from "../../Contexts/eFarmContext";
import { useNavigate } from "react-router-dom";
import document_upload from "../../assets/images/document_upload.svg";
import ClearIcon from "@mui/icons-material/Clear";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Loader from "../Loader/Loader";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const Bulkupload = (props) => {
  const {
    selectedFPO,
    setSelectedDataType,
    selectedDataType,
    uploadFile,
    setUploadFile,
    fileError,
    setFileError,
    uploadFileError,
    setUploadFileError,
    sizeErrorMessage,
    setSizeErrorMessage,
    downloadFileError,
    setDownloadFileError,
    selectedState,
    stateListFPO,
  } = props;
  const inputStyle = { marginBottom: "10px", width: "100%", maxWidth: "545px" };
  const [dataType, setDataType] = useState([
    "Users",
    "Farmers",
    "Inputs",
    "Produce",
  ]);
  const fileTypes = ["XLSX"];
  const { callToast, callLoader, loadingMessage } = useContext(EFarmContext);
  const navigate = useNavigate();
  const [fileProgress, setFileProgress] = useState(null);
  const [isLoading, setIsLoader] = useState(false);
  const [key, setKey] = useState(0);

  const userGuide = {
    usersLink:
      "https://e-farm-prod.s3.ap-south-1.amazonaws.com/FPO+Members+Data+Upload+Guide.pdf",
    farmersLink:
      "https://e-farm-prod.s3.ap-south-1.amazonaws.com/Farmers+Data+Upload+Guide.pdf",
    inputsLink:
      "https://e-farm-prod.s3.ap-south-1.amazonaws.com/Input+Products+Data+Upload+Guide.pdf",
    produceLink:
      "https://e-farm-prod.s3.ap-south-1.amazonaws.com/Produce_Crop+Data+Upload+Guide.pdf",
  };
  const getTemplateFile = async () => {
    try {
      callLoader(true);
      setIsLoader(false);
      const response = await fetch(
        UrlConstants.base_url +
          UrlConstants.get_template_file +
          "?fpc_id=" +
          selectedFPO +
          "&data_type=" +
          selectedDataType,
        {
          headers: {
            Authorization: `Bearer ${getTokenLocal()}`,
          },
        }
      );

      if (!response?.ok) {
        callLoader(false);
        setIsLoader(false);
        if (response?.status === 401 || response?.status === 403) {
          navigate("/login");
          callToast("Session expired, please login again!", "success", true);
        } else if (response.status === 400) {
          // Parse the response body as JSON to extract the error message
          const errorData = await response?.json();
          if (errorData && errorData?.error) {
            setDownloadFileError(errorData?.error, "error", true);
          } else {
            callToast(
              "An error occurred while downloading the file!",
              "error",
              true
            );
          }
        } else {
          callToast(
            "An error occurred while downloading the file!",
            "error",
            true
          );
        }
        return;
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      let filename = "sample_template.xlsx";
      if (selectedDataType === "Farmers") {
        filename = `${
          stateListFPO[selectedState].find((fpo) => fpo.fpc_id === selectedFPO)
            ?.fpo_name
        }_Farmer_Upload.xlsx`;
      } else if (selectedDataType === "Produce") {
        filename = `${
          stateListFPO[selectedState].find((fpo) => fpo.fpc_id === selectedFPO)
            ?.fpo_name
        }_Produce_Upload.xlsx`;
      } else if (selectedDataType === "Inputs") {
        filename = `${
          stateListFPO[selectedState].find((fpo) => fpo.fpc_id === selectedFPO)
            ?.fpo_name
        }_Input_Upload.xlsx`;
      } else if (selectedDataType === "Users") {
        filename = `${
          stateListFPO[selectedState].find((fpo) => fpo.fpc_id === selectedFPO)
            ?.fpo_name
        }_FPOMembers_Upload.xlsx`;
      }
      a.download = filename; // specify the desired file name
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      callLoader(false);
      setIsLoader(false);
    } catch (error) {
      console.log("errorrr", error);
      callLoader(false);
      setIsLoader(false);
      callToast("something went wrong", "error", true);
    }
  };
  const handleBulkUploadFile = async () => {
    setIsLoader(true);
    console.log("this is calling");
    const formData = new FormData();
    formData.append("file", uploadFile);
    formData.append("data_type", selectedDataType);
    formData.append("org_id", selectedFPO);
    try {
      const response = await axios.post(
        UrlConstants.base_url + UrlConstants.bulk_upload,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getTokenLocal()}`,
          },
          onUploadProgress: (progressEvent) => {
            console.log(progressEvent.loaded);
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            setFileProgress(percent);
          },
        }
      );
      if (response.status === 200) {
        if (response?.data?.failed?.length) {
          console.log("errorMessages", response?.data?.failed);
          setUploadFileError(response?.data?.failed);
        }
        if (
          response?.data?.passed > 0 &&
          response?.data?.failed?.length === 0 &&
          response?.data?.updated === 0
        ) {
          callToast("File uploaded successfully", "success", true);
          resetForm();
        } else if (
          response?.data?.failed?.length === 0 &&
          response?.data?.updated > 0
        ) {
          callToast("File updated successfully", "success", true);
          resetForm();
        }
        if (
          response?.data?.failed?.length === 0 &&
          response?.data?.updated === 0 &&
          response?.data?.passed === 0
        ) {
          callToast(
            "file is not uploaded please check the file again",
            "error",
            true
          );
          console.log(response?.data);
        }
      }
      callLoader(false);
      setIsLoader(false);
    } catch (error) {
      console.log("error", error);
      callLoader(false);
      setIsLoader(false);
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        navigate("/login");
        callToast("Session expired, please login again!", "success", true);
      } else if (error?.response?.status === 400) {
        const errorData = error?.response?.data?.error;
        if (errorData) {
          let isString = typeof errorData == "string";
          let isObject = typeof errorData == "object";
          const errorMessage = isString
            ? errorData
            : isObject
            ? JSON.stringify(errorData)
            : errorData[0];
          setFileError(errorMessage);
        }
        setFileProgress(null);
      } else {
        callToast("something went wrong", "error", true);
        setFileProgress(null);
      }
    } finally {
      //   // All API calls are resolved, so now you can set callLoader(false)
      callLoader(false);
      setFileProgress(null);
      setIsLoader(false);
    }
  };

  const handleFileRemove = () => {
    setUploadFile(null);
    setFileError(null);
    setDownloadFileError(null);
    setSizeErrorMessage(null);
    setUploadFileError([]);
    setKey((prevKey) => prevKey + 1);
    setFileProgress(null);
  };

  const handleFileChange = (file) => {
    setUploadFile(file);
    setFileError(null);
    setDownloadFileError(null);
    setSizeErrorMessage(null);
    setUploadFileError([]);
    setKey((prevKey) => prevKey + 1);
    setFileProgress(null);
  };

  const handleChange = (e) => {
    setSelectedDataType(e.target.value);
    setUploadFile(null);
    setFileError(null);
    setSizeErrorMessage(null);
    setDownloadFileError(null);
    setUploadFileError([]);
    setKey(key + 1);
    setFileProgress(null);
  };

  const resetForm = () => {
    setSelectedDataType("");
    setUploadFile(null);
  };
  console.log(uploadFile);
  console.log("fileError", fileError)
  return (
    <>
      {isLoading && (
        <Loader
          loadingMessage={
            "Your files are uploading. It can take some time. Please stay on this page until the file is uploaded."
          }
        />
      )}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
          <Typography className={LocalStyle.subHead}>
            {"Select data"}
          </Typography>
        </Col>
      </Row>
      <Row style={{ marginBottom: "30px" }}>
        <Col xs={6} sm={6} md={6} lg={6}>
          {" "}
          <FormControl
            className={LocalStyle.textField}
            variant="outlined"
            fullWidth
            required
            style={inputStyle}
          >
            <InputLabel
              id="demo-multiple-name-label"
              label="demo-multiple-name-label"
              variant="outlined"
            >
              Select the data you want to upload
            </InputLabel>
            {
              <Select
                style={{
                  textAlign: "left",
                }}
                labelId="FLEW Data"
                id="FLEW Data"
                label="Select the data you want to upload.ghj"
                fullWidth
                required
                value={selectedDataType}
                onChange={handleChange}
                variant="outlined"
                addlabel="demo-multiple-name-label"
              >
                {dataType?.map((type, index) => {
                  // Replace "Users" with "FPO Members"
                  const displayName = type === "Users" ? "FPO Members" : type;
                  return (
                    <MenuItem
                      className="fontFamily"
                      key={`riname-${type + index}`}
                      value={type}
                    >
                      {displayName}
                    </MenuItem>
                  );
                })}
              </Select>
            }
          </FormControl>
          {selectedDataType === "Farmers" && (
            <div>
              <Typography className={LocalStyle.lastUpdate}>
                <InfoOutlinedIcon />
                {
                  "Note: Before uploading farmer data, please ensure FPO Members data is uploaded first."
                }
              </Typography>
            </div>
          )}
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} style={{ paddingLeft: "242px" }}>
          <Typography>
            <Button
              className={LocalStyle.clearForm}
              onClick={getTemplateFile}
              disabled={selectedDataType ? false : true}
              startIcon={<FileDownloadOutlinedIcon />}
            >
              Download excel template
            </Button>
          </Typography>
          {selectedDataType === "Users" && (
            <a
              href={userGuide.usersLink}
              target="_blank" // This attribute opens the link in a new tab
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                color: "#00A94F",
                display: "inline-block",
                marginLeft: "43px",
                marginTop: "15px",
                fontFamily: "Montserrat",
                width: "277px",
              }}
            >
              Read instructions for uploading FPO Members data.
            </a>
          )}
          {selectedDataType === "Farmers" && (
            <a
              href={userGuide.farmersLink}
              target="_blank" // This attribute opens the link in a new tab
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                color: "#00A94F",
                display: "inline-block",
                marginLeft: "43px",
                marginTop: "15px",
                fontFamily: "Montserrat",
                width: "277px",
              }}
            >
              Read instructions for uploading Farmers data.
            </a>
          )}
          {selectedDataType === "Inputs" && (
            <a
              href={userGuide.inputsLink}
              target="_blank" // This attribute opens the link in a new tab
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                color: "#00A94F",
                display: "inline-block",
                marginLeft: "43px",
                marginTop: "15px",
                fontFamily: "Montserrat",
                width: "277px",
              }}
            >
              Read instructions for uploading Inputs data.
            </a>
          )}
          {selectedDataType === "Produce" && (
            <a
              href={userGuide.produceLink}
              target="_blank" // This attribute opens the link in a new tab
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                color: "#00A94F",
                display: "inline-block",
                marginLeft: "43px",
                marginTop: "15px",
                fontFamily: "Montserrat",
                width: "277px",
              }}
            >
              Read instructions for uploading Produce data.
            </a>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6} md={6} lg={6}>
          <FileUploaderMain
            texts={
              <>
                <Row>
                  <Row
                    className={`${LocalStyle.browseStyle} ${LocalStyle.margin5}`}
                  >
                    {"Drag and drop your file here"}
                  </Row>
                  <Row
                    className={`${LocalStyle.browseStyle} ${LocalStyle.margin5}`}
                  >
                    {"or"}
                  </Row>
                  <Row style={{ justifyContent: "center" }}>
                    <Button
                      className={`${LocalStyle.browseBtn} ${LocalStyle.margin5}`}
                      disabled={selectedDataType ? false : true}
                    >
                      Browse file
                    </Button>
                  </Row>
                  <Row className={LocalStyle.note}>
                    {
                      "Note: Only excel files are supported, size of the file should not exceed 5 MBs."
                    }
                  </Row>
                </Row>
              </>
            }
            key={key}
            isMultiple={false}
            id="upload-policy-file"
            fileTypes={fileTypes}
            handleChange={handleFileChange}
            maxSize={5}
            setSizeError={() =>
              setSizeErrorMessage("Maximum file size allowed is 5MB")
            }
            disabled={selectedDataType ? false : true}
          />
          {fileError && (
            <div className={LocalStyle.borderStyle}>
              <Typography
                className={LocalStyle.errormsg}
                style={{
                  marginLeft: "10px",

                }}
              >
                Errors Encountered
              </Typography>
              <span
                style={{
                  color: "#000000",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  display: "block",
                  marginBottom: "5px",
                  marginLeft: "10px",
                }}
              >
                {fileError}
              </span>
            </div>
          )}
          {downloadFileError && (
            <div className={LocalStyle.borderStyle}>
              <Typography
                className={LocalStyle.errormsg}
                style={{ marginLeft: "10px" }}
              >
                Errors Encountered
              </Typography>
              <span
                style={{
                  color: "#000000",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  display: "block",
                  marginBottom: "5px",
                  marginLeft: "10px",
                  overflowY: "auto"
                }}
              >
                {downloadFileError}
              </span>
            </div>
          )}
          {uploadFileError.length > 0 && (
            <div className={LocalStyle.borderStyle}>
              <Typography
                className={LocalStyle.errormsg}
                style={{ marginLeft: "10px" }}
              >
                Errors Encountered
              </Typography>
              <ol
                style={{
                  color: "#000000",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  display: "block",
                  marginBottom: "5px",
                  overflowY: "auto",
                  maxHeight: "125px"
                }}
              >
                {uploadFileError.map((error, index) => (
                  <li key={index}>{error.error_message}</li>
                ))}
              </ol>
            </div>
          )}
        </Col>
        <Col lg={6} sm={12} style={{ marginBottom: "20px" }}>
          <div
            className={
              LocalStyle.fileTextSize +
              " " +
              LocalStyle.text_left +
              "" +
              LocalStyle.errormsg
            }
            style={{ marginBottom: "20px", fontFamily: "Montserrat" }}
          >
            {uploadFile && selectedDataType && "Uploaded file"}
          </div>
          {uploadFile && (
            <>
              <div className={LocalStyle.text_left}>
                <div className={LocalStyle.each_preview_policy}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      id="document-logo"
                      height={"54px"}
                      width={"54px"}
                      className={LocalStyle.document_upload_logo}
                      src={document_upload}
                      alt="Document Upload"
                    />
                    <div style={{ marginTop: "20px" }}>
                      <LinearProgress
                        value={fileProgress}
                        variant="determinate"
                        sx={{
                          borderRadius: "8px",
                          height: "8px",
                          width: "386px",
                          margin: "0px 10px",
                        }}
                      />
                      <Tooltip
                        title={
                          <>
                            {uploadFile.name}{" "}
                            {uploadFile.size &&
                              `(${(uploadFile.size / 1000000).toFixed(2)} MB)`}
                          </>
                        }
                        placement="bottom-end"
                      >
                        <span
                          id="file-preview"
                          className={LocalStyle.blue}
                          style={{ width: "100%" }}
                        >
                          {uploadFile.name}{" "}
                          {uploadFile.size &&
                            `(${(uploadFile.size / 1000000).toFixed(2)} MB)`}
                        </span>
                      </Tooltip>
                    </div>

                    <ClearIcon
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                      id="cancel-file"
                      onClick={() => handleFileRemove()}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {sizeErrorMessage && (
            <span
              style={{
                color: "#FD1744",
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: 400,
              }}
            >
              {sizeErrorMessage}
            </span>
          )}
          {fileError && (
            <span
              style={{
                color: "#FD1744",
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: 400,
              }}
            >
              {
                "File could not be uploaded, please correct the errors and upload again"
              }
            </span>
          )}
          {uploadFileError.length > 0 && (
            <span
              style={{
                color: "#FD1744",
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: 400,
              }}
            >
              {
                "File could not be uploaded, please correct the errors and upload again"
              }
            </span>
          )}
        </Col>
      </Row>
      <hr style={{ marginTop: "60px", width: "1130px" }} />
      <Row>
        <Col xs={6} sm={6} md={6} lg={6}></Col>
        <Col xs={6} sm={6} md={6} lg={6} className={LocalStyle.buttonStyle}>
          <Button
            variant="contained"
            className={`btn ${LocalStyle.saveBtn}`}
            onClick={() => handleBulkUploadFile()}
            disabled={
              selectedDataType && uploadFile && !sizeErrorMessage && !fileError
                ? false
                : true
            }
          >
            Submit
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Bulkupload;
