import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tab,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LocalStyle from "./index.module.css";
import { getUserLocal } from "../../utils/common/common";
import { Row, Col } from "react-bootstrap";
import UrlConstants from "../../utils/constants/urlConstants";
import HTTPService from "../../services/HTTPService";
import {
  getTokenLocal,
  getRoleLocal,
  getRoleLocalName,
} from "../../utils/common/common";
import FPOForm from "../../components/FPOForm/FPOForm";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Bulkupload from "../../components/BulkUpload/BulkUpload";
import update_logo from "../../assets/images/update_black_24dp 1.svg";
import { EFarmContext } from "../../Contexts/eFarmContext";
import MapVillages from "../../components/MapVillages/MapVillages";

console.log("getUserLocal", getRoleLocal());

const FPOManagement = () => {
  const inputStyle = { marginBottom: "10px", width: "100%", maxWidth: "545px" };
  const [selectedFPO, setSelectedFPO] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [stateListFPO, setStateListFPO] = useState([]);
  const [tabValue, setTabValue] = React.useState("1");
  const [lastUpdateUser, setLastUpdateUser] = useState("");
  const [lastUpdateTime, setLastUpdateTime] = useState("");
  const [selectedDataType, setSelectedDataType] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [uploadFileError, setUploadFileError] = useState([]);
  const [sizeErrorMessage, setSizeErrorMessage] = useState(null);
  const [downloadFileError, setDownloadFileError] = useState(null);
  const { callToast, callLoader } = useContext(EFarmContext);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const navigate = useNavigate();
  const getStateListFPO = () => {
    callLoader(true);
    HTTPService(
      "GET",
      UrlConstants.base_url + UrlConstants.get_detail_fpc,
      "",
      false,
      true,
      getTokenLocal()
    )
      .then((response) => {
        setStateListFPO(response?.data);
        if (
          getRoleLocal() === "fpc_user" ||
          getRoleLocal() === "fpc_board_member" ||
          getRoleLocal() === "fpc_president" ||
          getRoleLocal() === "fpc_ceo"
        ) {
          const initialSelectedState = Object.keys(response?.data)[0] || "";
          setSelectedState(initialSelectedState);
          if (initialSelectedState) {
            setSelectedFPO(
              response?.data[initialSelectedState]?.[0]?.fpc_id || ""
            );
          }
        }
        callLoader(false);
      })
      .catch((err) => {
        callLoader(false);
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          navigate("/login");
          callToast("Session expired, please login again!", "error", true);
        } else {
          callToast("Something went wrong!", "error", true);
        }
      });
  };
  const getLastUpdate = () => {
    HTTPService(
      "GET",
      UrlConstants.base_url +
        UrlConstants.get_last_update +
        "?org_id=" +
        selectedFPO,
      "",
      false,
      true,
      getTokenLocal()
    )
      .then((response) => {
        console.log(response?.data);
        if (response?.data?.updated_at) {
          setLastUpdateUser(response?.data?.updated_by_user);
          setLastUpdateTime(response?.data?.updated_at);
        } else {
          setLastUpdateUser("");
          setLastUpdateTime("");
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          navigate("/login");
          callToast("Session expired, please login again!", "error", true);
        }
      });
  };
  const handleFPOChange = (e) => {
    setSelectedFPO(e.target.value);
    setSelectedDataType("");
    setUploadFile(null);
    setFileError(null);
    setUploadFileError([]);
    setSizeErrorMessage(null);
    setDownloadFileError(null);
  };

  const handleSelectState = (e) => {
    setSelectedState(e.target.value);
    setSelectedFPO("");
  };
  useEffect(() => {
    getStateListFPO();
  }, []);
  useEffect(() => {
    getLastUpdate();
  }, [selectedFPO]);
  console.log("dg_usersss", getRoleLocal());
  console.log("userId", getUserLocal());
  useEffect(() => {
    if (!getUserLocal()) {
      console.log("🚀 ~ useEffect ~ getUserLocal():", getUserLocal());
      navigate("/login", { replace: true }); // Replace the current entry in the history stack
    } else {
      navigate("/FPOManagement", { replace: true }); // Replace the current entry in the history stack
      console.log("🚀 ~ useEffect ~ navigate:", navigate);
    }
  }, [navigate]);
  console.log("stateList", stateListFPO);
  const storedString = () => localStorage.getItem("first_name");
  const formatRoleName = (roleName) => {
    const parts = roleName?.split(" ");
    if (parts?.length > 1) {
      return parts[0] + " " + parts[1].toLowerCase();
    }
    return roleName;
  };
  return (
    <>
      <div
        style={{ marginTop: "50px", marginLeft: "144px", marginRight: "144px" }}
      >
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Typography className={LocalStyle.titleHead}>
              {`Welcome ${storedString()} `}
              <span className={LocalStyle.programOfficer}>
                {getRoleLocalName() === "FPC CEO"
                  ? `(${getRoleLocalName()})`
                  : `(${formatRoleName(getRoleLocalName())})`}
              </span>
            </Typography>
          </Col>
          {getRoleLocal() === "dg_user" ||
          getRoleLocal() === "ri_admin" ||
          getRoleLocal() === "ri_user" ? (
            <Col xs={6} sm={6} md={6} lg={6} style={{ paddingLeft: "282px" }}>
              <Button
                variant="contained"
                className={`btn ${LocalStyle.bulkUploadNavButton}`}
                onClick={() => navigate("/addFPO")}
              >
                + Add new FPO
              </Button>
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Typography className={LocalStyle.subHead}>
              {"Select FPO"}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <FormControl
              required
              className={LocalStyle.textField}
              variant="outlined"
              fullWidth
              style={inputStyle}
            >
              <InputLabel id="demo-multiple-name-label">
                Select state
              </InputLabel>
              {
                <Select
                  style={{
                    textAlign: "left",
                  }}
                  labelId="Select State"
                  id="state"
                  label="Select State"
                  fullWidth
                  required
                  disabled = {
                    getRoleLocal() === "fpc_user" ||
                    getRoleLocal() === "fpc_board_member" ||
                    getRoleLocal() === "fpc_president" ||
                    getRoleLocal() === "fpc_ceo" ? true : false}
                  
                  value={selectedState}
                  onChange={handleSelectState}
                >
                  {Object?.keys(stateListFPO)?.map((state, index) => (
                    <MenuItem
                      key={`state-${index}`}
                      value={state}
                      className="fontFamily"
                    >
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              }
            </FormControl>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <FormControl
              required
              className={LocalStyle.textField}
              variant="outlined"
              fullWidth
              style={inputStyle}
              disabled={
                !selectedState ||
                ["fpc_user", "fpc_board_member", "fpc_president", "fpc_ceo"].includes(getRoleLocal())
              }
            >
              <InputLabel id="demo-multiple-name-label">Select FPO</InputLabel>
              {
                <Select
                  style={{
                    textAlign: "left",
                  }}
                  labelId="Select FPO"
                  id="Select FPO"
                  label="Select FPO "
                  fullWidth
                  required
                  value={selectedFPO}
                  onChange={handleFPOChange}
                >
                  {selectedState &&
                    stateListFPO[selectedState]?.map((fpo, index) => (
                      <MenuItem
                        className="fontFamily"
                        key={`fpo-${index}`}
                        value={fpo.fpc_id}
                      >
                        {fpo.fpo_name}
                      </MenuItem>
                    ))}
                </Select>
              }
            </FormControl>
          </Col>
        </Row>
        <hr style={{ width: "1130px" }} />
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Typography className={LocalStyle.titleHead}>
              {"FPO Data"}
            </Typography>
          </Col>
          <Col
            xs={6}
            sm={6}
            md={6}
            lg={6}
            style={{
              display: "flex",
              paddingLeft: "105px",
              // justifyContent: "flex-start"
            }}
          >
            {selectedFPO && lastUpdateUser && lastUpdateTime ? (
              <>
                <Typography className={LocalStyle.lastUpdate}>
                  <img
                    id="document-logo"
                    height={"24px"}
                    width={"24px"}
                    className={LocalStyle.document_upload_logo}
                    src={update_logo}
                  />
                  {`Last update done by ${lastUpdateUser} on ${lastUpdateTime}`}
                </Typography>
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <div style={{ marginLeft: "114px", marginRight: "114px" }}>
        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: "1px solid #3D4A52",
              borderColor: "divider",
              // width: "1108px",
              marginLeft: "30px",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              className="tabStyle"
              sx={{ maxWidth: "1108px", width: "100%" }}
            >
              <Tab
                style={{
                  color: tabValue === "1" ? "#00AB55" : "#3D4A52",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: tabValue === "1" ? "600" : "400",
                  textTransform: "none",
                  height: "48px",
                  padding: "inherit",
                  gap: "0px",
                  border: "0px 0px 2px 0px",
                  opacity: "0px",
                }}
                label="Bulk upload data (FPO)"
                value="1"
                className="tabStyle"
              />
              <Tab
                style={{
                  color: tabValue === "2" ? "#00AB55" : "#3D4A52",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: tabValue === "2" ? "600" : "400",
                  textTransform: "none",
                  height: "48px",
                  padding: "13px 0px 12px 0px",
                  gap: "0px",
                  marginLeft: "50px",
                }}
                label="Edit profile (FPO)"
                value="2"
              />
              <Tab
                style={{
                  color: tabValue === "3" ? "#00AB55" : "#3D4A52",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: tabValue === "3" ? "600" : "400",
                  textTransform: "none",
                  height: "48px",
                  padding: "13px 0px 12px 0px",
                  gap: "0px",
                  marginLeft: "50px",
                }}
                label="Map FLEW & villages"
                value="3"
              />
            </TabList>
          </Box>

          <TabPanel
            value="1"
            sx={{
              paddingLeft: "28px",
              paddingTop: "10px",
              paddingRight: "28px",
            }}
          >
            {selectedState && selectedFPO ? (
              <Bulkupload
                selectedFPO={selectedFPO}
                setTabValue={setTabValue}
                setSelectedFPO={setSelectedFPO}
                setSelectedState={setSelectedState}
                selectedDataType={selectedDataType}
                setSelectedDataType={setSelectedDataType}
                uploadFile={uploadFile}
                setUploadFile={setUploadFile}
                fileError={fileError}
                setFileError={setFileError}
                uploadFileError={uploadFileError}
                setUploadFileError={setUploadFileError}
                sizeErrorMessage={sizeErrorMessage}
                setSizeErrorMessage={setSizeErrorMessage}
                downloadFileError={downloadFileError}
                setDownloadFileError={setDownloadFileError}
                selectedState={selectedState}
                stateListFPO={stateListFPO}
              />
            ) : (
              <Typography className={LocalStyle.SelectFpo}>
                Select a FPO for Bulk Upload Data
              </Typography>
            )}
          </TabPanel>
          <TabPanel value="2">
            {selectedState && selectedFPO ? (
              <FPOForm
                isEdit={true}
                selectedFPO={selectedFPO}
                setTabValue={setTabValue}
                setSelectedFPO={setSelectedFPO}
                setSelectedState={setSelectedState}
              />
            ) : (
              <Typography className={LocalStyle.SelectFpo}>
                Select a FPO for update
              </Typography>
            )}
          </TabPanel>
          <TabPanel
            value="3"
            sx={{
              paddingLeft: "28px",
              paddingTop: "10px",
              paddingRight: "28px",
            }}
          >
            {selectedState && selectedFPO ? (
              <MapVillages
                selectedState={selectedState}
                selectedFPO={selectedFPO}
              />
            ) : (
              <Typography className={LocalStyle.SelectFpo}>
                Select a FPO for Map FLEW & villages
              </Typography>
            )}
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
};
export default FPOManagement;
