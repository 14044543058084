import React, { useState, useEffect } from 'react';
import "./login.css";

const OtpTimer = ({ duration, onTimerEnd }) => {
  const [timer, setTimer] = useState(duration);

  useEffect(() => {
    let count = duration;
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(countdown);
          if (onTimerEnd) {
            onTimerEnd();
          }
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [duration, onTimerEnd]);

  return (
     <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className="resendLeft">Resend OTP</div>
      <div className='timer'>in {timer} secs</div>
    </div>
  );
};

export default OtpTimer;
