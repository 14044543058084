const UrlConstants = {
  base_url:
    Window?.ENV_VARS?.REACT_APP_BASEURL|| process.env.REACT_APP_BASEURL,
  verify_otp_end_point: "user/verify_otp/",
  generate_otp: "user/generate_otp/",
  log_out: "user/logout/",
  get_Ri: "organisation/org/get_all_ris/",
  get_State: "geography/geography_data/get_all_states/",
  get_District: "geography/geography_data/get_districts/",
  get_Block: "geography/geography_data/get_block_data/",
  get_Village: "geography/geography_data/get_village_data/",
  get_all_FPC: "organisation/org/get_all_fpcs/",
  add_FPC: "organisation/org/add_new_fpc/",
  get_FPC_detail : "organisation/org/fpc_detail/",
  get_template_file : "organisation/org/generate_bulk_upload_file/",
  edit_FPC : "organisation/org/",
  getState_getFPC: "organisation/org/get_fpc_and_state_details_for_bulk_upload",
  bulk_upload : "organisation/org/bulk_upload_file/",
  get_detail_fpc: "organisation/org/get_fpc_and_state_details_for_bulk_upload/",
  get_last_update : "organisation/org/get_latest_update_details/",
  get_flew_list : "farmer_registry/filters/flw_filters/",
  get_flw_village_list: "farmer_registry/filters/village_filters_english/",
  map_flw_and_villages : "/farmer_registry/farmer/update_flw_farmer_map/",
  event_login: "/common/bot_event/set_event_history/",
  
};
export default UrlConstants;
