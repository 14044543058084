import React from "react";
import FPOForm from "../../components/FPOForm/FPOForm";
import { Box } from "@mui/material";

const AddFPO = () => {
  return (
    <Box
      sx={{
        marginLeft: "144px",
        marginRight: "144px",
        marginTop: "30px",
        marginBottom: "100px",
      }}
    >
      <FPOForm />
    </Box>
  );
};

export default AddFPO;
