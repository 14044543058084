import React, { useEffect, useState, useContext, useRef } from "react";
import {
  TextField,
  Button,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
  FormHelperText,
  Tab,
} from "@mui/material";
import style from "./style.module.css";
import LocalStyle from "./FPOForm.module.css";
import { Row, Col } from "react-bootstrap";
import UrlConstants from "../../utils/constants/urlConstants";
import HTTPService from "../../services/HTTPService";
import {
  getTokenLocal,
  handleUnwantedSpace,
  getRoleLocal,
} from "../../utils/common/common";
import FileUploaderMain from "../uploadFile/fileUploaderMain";
import document_upload from "../../assets/images/document_upload.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { EFarmContext } from "../../Contexts/eFarmContext";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InfoIcon from "@mui/icons-material/Info";
import CustomDeletePopper from "../CustomPopper/CustomDeletePopper";
import axios from "axios";
const FPOForm = (props) => {
  const { isEdit, selectedFPO, setTabValue, setSelectedFPO, setSelectedState } =
    props;
  const [fpoName, setFpoName] = useState("");
  const [fpoID, setFpoID] = useState("");
  const [riName, setRiName] = useState("");
  const [riId, setRiId] = useState("");
  const [geographyID, setGeograpyID] = useState({
    state: "",
    district: "",
    block: "",
    village: "",
  });
  const [geography, setGeograpy] = useState({
    state: "",
    district: "",
    block: "",
    village: "",
  });
  const [riNames, setRiNmaes] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [genders, setGenders] = useState([
    {
      name: "Male",
      value: "M",
    },
    {
      name: "Female",
      value: "F",
    },
    {
      name: "Other",
      value: "O",
    },
  ]);
  const [blocks, setBlocks] = useState([]);
  const [villages, setVillages] = useState([]);
  const [pgVillage, setPGVillage] = useState([]);
  const [sharePrice, setSharePrice] = useState("");
  const [order, setOrder] = useState(false);
  const [procurements, setProcurements] = useState(false);
  const [incorpCertificate, setIncorpCertificate] = useState(null);
  const [certificates, setCertificates] = useState([]);
  const [certificateName, setCertificateName] = useState("");
  const [presidentName, setPresidentName] = useState("");
  const [presidentNumber, setPresidentNumber] = useState("");
  const [ceoName, setCeoName] = useState("");
  const [ceoNumber, setCeoNumber] = useState("");
  const [presidentGender, setPresidentGender] = useState("");
  const [certificatePreview, setCertificatePreview] = useState([]);
  const [ceoGender, setCeoGender] = useState("");
  const [otherCertificates, setOtherCertificates] = useState([]);
  const [otherCertificatesId, setOtherCeritificatesId] = useState([]);
  const [deletedCertificates, setDeletedCertificates] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [otherCertificateSizes, setOtherCertificateSizes] = useState([]);
  const [pg, setPg] = useState([
    {
      pg_name: "",
      pg_village_id: "",
      pg_id: "",
      is_deleted: false,
    },
  ]);
  const [errorMessage, setErrorMessage] = useState({
    fpoName: null,
    sharePrice: null,
    incorpFile: null,
    certificate: null,
    presidentName: null,
    presidentNumber: null,
    ceoName: null,
    ceoNumber: null,
    ceoGender: null,
    certificateName: null,
  });
  const [ceoNumberErrorMessage, setCeoNumberErrorMessage] = useState("");
  const [presidentNumberErrorMessage, setPresidentNumberErrorMessage] =
    useState("");
  const [cerTab, setCerTab] = useState("1");
  const fpoNameRef = useRef(null);
  const sharePriceRef = useRef(null);
  const incorpFileRef = useRef(null);
  const certificateFileRef = useRef(null);
  const presidentNameRef = useRef(null);
  const presidentNumRef = useRef(null);
  const ceoNameRef = useRef(null);
  const ceoNumberRef = useRef(null);
  const ceoGenderRef = useRef(null);

  const scrollToError = (errorRef, offsetY = 20) => {
    if (errorRef.current) {
      const textFieldRect = errorRef.current.getBoundingClientRect();
      window.scrollTo({
        top: textFieldRect.top + window.scrollY - offsetY,
        behavior: "smooth",
      });
    }
  };
  const [preview, setPreview] = useState(null);
  const fileTypes = ["JPEG", "JPG", "PDF"];
  const { callToast, callLoader } = useContext(EFarmContext);
  // Inline styles
  const inputStyle = { marginBottom: "10px", width: "100%", maxWidth: "544px" };
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(null);
  const [isButtonClicked, setIsButttonClicked] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [popperType, setPopperType] = useState(null);
  const [openPopperIndex, setOpenPopperIndex] = useState(null);
  const scrollContainerRef = useRef(null);
  const [certificateCount, setCertificateCount] = useState(0);
  const [certCount, setCertCount] = useState(0)

  const BUFFER_MARGIN = 40;

  // handlescroll function close the popper when current even is changed
  const handleScroll = () => {
    if (openPopperIndex !== null) {
      const element = document.querySelector(
        `[data-index="${openPopperIndex}"]`
      );
      if (element) {
        const containerRect =
          scrollContainerRef.current.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();

        const isVisible =
          elementRect.top >= containerRect.top - BUFFER_MARGIN &&
          elementRect.bottom <= containerRect.bottom + BUFFER_MARGIN;

        if (!isVisible) {
          closePopper();
        }
      }
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);

      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [openPopperIndex]);

  const handleTabChange = (event, newValue) => {
    //certificates tab
    setCerTab(newValue);
    setOpen(false);
  };

  const handleBackButtonClick = () => {
    setOpenSnackbar(true); // Open the snackbar when "Back" button is clicked
    setIsButttonClicked(false);
  };
  const handleClickClearBtn = () => {
    //clear the form
    setOpenSnackbar(true);
    setIsButttonClicked(true);
  };
  const handleWarningUser = (choice) => {
    // warning when back button is clicked
    setIsButttonClicked(false);
    setOpenSnackbar(null);
    if (choice === "yes") {
      navigate("/FPOManagement");
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(null);
  };
  const handleClickClearForm = (choice) => {
    // clear function calls
    setOpenSnackbar(null);
    if (choice === "yes") {
      handleClearForm();
    }
  };
  const action = (
    <React.Fragment>
      <Button
        style={{
          color: "#212B36",
          border: "1px solid #212B36",
          borderRadius: "8px",
          height: "33px",
          width: "70px",
          textTransform: "none",
          fontFamily: "Montserrat",
          margin: "5px",
          fontWeight: "700",
          fontSize: "13px",
        }}
        size="small"
        onClick={() =>
          isButtonClicked
            ? handleClickClearForm("yes")
            : handleWarningUser("yes")
        }
      >
        Yes
      </Button>
      <Button
        style={{
          color: "#212B36",
          border: "1px solid #212B36",
          borderRadius: "8px",
          height: "33px",
          width: "70px",
          textTransform: "none",
          margin: "5px",
          fontFamily: "Montserrat",
          fontWeight: "700",
          fontSize: "13px",
        }}
        size="small"
        onClick={handleCloseSnackbar}
      >
        No
      </Button>
    </React.Fragment>
  );
  const handleClearForm = () => {
    //reset all state
    if (!isEdit) {
      setFpoName("");
      setBlocks([]);
      setDistricts([]);
      setVillages([]);
      setRiName("");
      setAnchorEl(null);
      setOpen(false);
      setIncorpCertificate(null);
      setOrder();
      setProcurements();
      setPresidentName("");
      setPresidentNumber("");
      setCeoName("");
      setCeoNumber("");
      callToast("");
      setPresidentGender("");
      setCeoGender("");
      setPg([
        {
          pg_name: "",
          pg_village_id: "",
          pg_id: undefined,
          is_deleted: false,
        },
      ]);
      setErrorMessage({
        fpoName: null,
        sharePrice: null,
        incorpFile: null,
        certificate: null,
        presidentName: null,
        presidentNumber: null,
        ceoName: null,
        ceoNumber: null,
        ceoGender: null,
        certificateName: null,
      });
      setCeoNumberErrorMessage("");
      setPresidentNumberErrorMessage("");
      setSharePrice("");
      setPreview(null);
      setCertificateName("");
      setCertificates([]);
      setGeograpy({
        state: "",
        district: "",
        block: "",
        village: "",
      });
      setCerTab("1");
      setOpen(false);
      setAnchorEl(null);
    } else {
      // Make API call
      setErrorMessage({
        fpoName: null,
        sharePrice: null,
        incorpFile: null,
        certificate: null,
        presidentName: null,
        presidentNumber: null,
        ceoName: null,
        ceoNumber: null,
        ceoGender: null,
        certificateName: null,
      });
      setCertificates([]);
      setCertificateName("");
      setCeoNumberErrorMessage("");
      setPresidentNumberErrorMessage("");
      getFpcDetail();
      setCerTab("1");
      setOpen(false);
      setAnchorEl(null);
    }
  };
  //space functions allow only one space in field
  const handleCertiticateSpace = (e) => {
    handleUnwantedSpace(certificateName, e);
  };
  const handleFpoSpace = (e) => {
    handleUnwantedSpace(fpoName, e);
  };
  const handlePresidentSpace = (e) => {
    handleUnwantedSpace(presidentName, e);
  };
  const handleCEONameSpace = (e) => {
    handleUnwantedSpace(ceoName, e);
  };
  const handlePgChange = (index, field, value) => {
    const updatedPg = [...pg];
    const alphabetsRegex = /^[a-zA-Z\s]*$/;

    // Perform alphabets regex validation for pg_name
    if (field === "pg_name" && !alphabetsRegex.test(value)) {
      // callToast("Only alphabets and spaces are allowed", "error", true);
      return;
    }

    updatedPg[index] = {
      ...updatedPg[index],
      [field]: value,
      is_deleted: false,
    };

    // Update the pg state
    setPg(updatedPg);
  };
  const addNewPg = () => {
    //add new pg in a row
    setPg([
      ...pg,
      { pg_name: "", pg_village_id: "", pg_id: "", is_deleted: false },
    ]);
  };
  const [key, setKey] = useState(0);

  const handleDeleteField = (indexToDelete) => {
    //delete the added row here indexToDelete is the index of the array
    setPg(pg.filter((_, index) => index !== indexToDelete));
  };
  const handleClearFieldEdit = (index) => {
    // Clear the fields within the row identified by index
    const updatedPg = [...pg];
    updatedPg[index] = {
      ...updatedPg[index],
      pg_name: "",
      pg_village_id: "",
      is_deleted: true,
    };
    setPg(updatedPg);
  };
  const handleClearField = (index) => {
    // clear the row
    const updatedPg = [...pg];
    updatedPg[index].pg_name = "";
    updatedPg[index].pg_village_id = "";
    setPg(updatedPg);
  };

  const handleUploadGuarantee = (file) => {
    const totalCertificates = certificates?.length + otherCertificates?.length;
    if (totalCertificates < 10) {
      const lowerCaseOtherCertificates = otherCertificates?.map((cert) =>
        cert.name.trim().toLowerCase()
      );
      const lowerCaseName = certificateName.trim().toLowerCase();
      if (lowerCaseName === "fpo incorporation certificate") {
        setErrorMessage((prevErrors) => ({
          ...prevErrors,
          certificateName:
            "The name 'FPO incorporation certificate' is not allowed.",
        }));
        setKey((prevKey) => prevKey + 1);
        return;
      }
      if (lowerCaseOtherCertificates.includes(lowerCaseName)) {
        setErrorMessage((prevErrors) => ({
          ...prevErrors,
          certificateName: "A certificate with this name already exists.",
        }));
        setKey((prevKey) => prevKey + 1);
        return;
      }
      if (
        certificates.some((cert) => cert.name.toLowerCase() === lowerCaseName)
      ) {
        setErrorMessage((prevErrors) => ({
          ...prevErrors,
          certificateName: "A certificate with this name already exists.",
        }));
        setKey((prevKey) => prevKey + 1);
        return;
      }
      setCertificates((prevCertificates) => [
        { name: certificateName, file },
        ...prevCertificates,
      ]);
      setCertificateName("");
      setKey((prevKey) => prevKey + 1);
      setErrorMessage((prevErrors) => ({
        ...prevErrors,
        certificate: null,
      }));
      setOpen(false);
    } else {
      console.log("You can only upload up to 10 files.");
    }
  };
  const handleUploadincorp = (file) => {
    setIncorpCertificate(file);
    setKey((prevKey) => prevKey + 1);
    setErrorMessage({ incorpFile: false });
    setOpen(false);
  };
  const handleDeletePopper = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleDeletePopperCertificates = (event, index) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setSelectedIndex(index);
    setPopperType("certificates");
    setOpenPopperIndex(index);
  };
  const handleDeletePopperOtherCertificates = (event, index) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setSelectedIndex(index);
    setPopperType("otherCertificates");
    setOpenPopperIndex(index);
  };
  const closePopper = () => {
    setAnchorEl(null);
    setOpen(false);
    setSelectedIndex(null);
    setOpenPopperIndex(null);
  };
  const handleDeleteIncorp = () => {
    setKey((prevKey) => prevKey + 1);
    setIncorpCertificate(null);
    setErrorMessage((prevErrors) => ({
      ...prevErrors,
      incorpFile: null,
    }));
    setAnchorEl(null);
    setOpen(false);
  };
  const handleDeleteCertificate = () => {
    console.log("common function is happening");
    if (selectedIndex === null || popperType !== "certificates") return;
    setCertificates((prevCertificates) =>
      prevCertificates.filter((_, i) => i !== selectedIndex)
    );
    setErrorMessage((prevErrors) => ({
      ...prevErrors,
      certificateName: null,
    }));
    setErrorMessage((prevErrors) => ({
      ...prevErrors,
      certificate: null,
    }));
    setKey((prevKey) => prevKey + 1);
    setAnchorEl(null);
    setOpen(false);
    setSelectedIndex(null);
    setOpenPopperIndex(null);
  };
  const handleDeleteOtherCertificates = () => {
    if (selectedIndex === null || popperType !== "otherCertificates") return;

    console.log("Deleting certificate at index:", selectedIndex);
    const deletedId = otherCertificates[selectedIndex].id;
    const updatedCertificates = otherCertificates.filter(
      (_, idx) => idx !== selectedIndex
    );
    setOtherCertificates(updatedCertificates);
    setDeletedCertificates((prevDeletedCertificates) => [
      ...prevDeletedCertificates,
      deletedId,
    ]);
    setErrorMessage((prevErrors) => ({
      ...prevErrors,
      certificateName: null,
    }));
    setErrorMessage((prevErrors) => ({
      ...prevErrors,
      certificate: null,
    }));
    setAnchorEl(null);
    setOpen(false);
    setSelectedIndex(null);
    setOpenPopperIndex(null);
  };
  const handleStateChange = (e) => {
    //select the state and the regions is set to empty string
    const selectedStateId = e.target.value;
    setGeograpy((prev) => ({
      ...prev,
      state: selectedStateId,
      district: "",
      block: "",
      village: "",
    }));

    // Call getDistrict function with the selected state ID
    getDistrict(selectedStateId);
  };
  const handleDistrictChange = (e) => {
    //select the district and the regions below is set to empty string
    const selectedDistrictId = e.target.value;
    setGeograpy((prev) => ({
      ...prev,
      district: selectedDistrictId,
      block: "",
      village: "",
    }));

    // Call getBlocklist function with the selected district ID
    getBlockList(selectedDistrictId);
  };

  const handleBlockChange = (e) => {
    //select the block and the regions below is set to empty string
    const selectedBlocktId = e.target.value;
    setGeograpy((prev) => ({
      ...prev,
      block: selectedBlocktId,
      village: "",
    }));

    // Call getVillage function with the selected state ID
    getVillageList(selectedBlocktId);
  };
  const handleVillageChange = (e) => {
    //select the village
    const selectedvillageId = e.target.value;
    setGeograpy((prev) => ({
      ...prev,
      village: selectedvillageId, // Reset village when state changes
    }));
  };
  const handleVillageChangeEdit = (e) => {
    //select the village
    const selectedvillageId = e.target.value;
    setGeograpyID((prev) => ({
      ...prev,
      village: selectedvillageId, // Reset village when state changes
    }));
  };
  const getRiList = () => {
    //get the list of Ri names and id
    HTTPService(
      "GET",
      UrlConstants.base_url + UrlConstants.get_Ri,
      "",
      false,
      true,
      getTokenLocal()
    )
      .then((response) => {
        setRiNmaes(response?.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          navigate("/login");
          callToast("Session expired, please login again!", "success", true);
        }
      });
  };
  const getStateList = () => {
    //get list of states
    HTTPService(
      "GET",
      UrlConstants.base_url + UrlConstants.get_State,
      "",
      false,
      true,
      getTokenLocal()
    )
      .then((response) => {
        setStates(response?.data);
      })
      .catch((e) => {
        if (e?.response?.status === 401 || e?.response?.status === 403) {
          navigate("/login");
          callToast("Session expired, please login again!", "success", true);
        } else {
          callToast("something went wrong", "error", true);
        }
      });
  };
  const getDistrict = (id) => {
    //here id denotes the state id so will get district list of that state
    HTTPService(
      "GET",
      UrlConstants.base_url + UrlConstants.get_District + "?state_id=" + id,
      "",
      false,
      true,
      getTokenLocal()
    )
      .then((response) => {
        setDistricts(response?.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          navigate("/login");
          callToast("Session expired, please login again!", "success", true);
        }
      });
  };
  const getBlockList = (id) => {
    //here id denotes the district id so will get block list of that state
    HTTPService(
      "GET",
      UrlConstants.base_url + UrlConstants.get_Block + "?district_id=" + id,
      "",
      false,
      true,
      getTokenLocal()
    )
      .then((response) => {
        setBlocks(response?.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          navigate("/login");
          callToast("Session expired, please login again!", "success", true);
        }
      });
  };
  const getVillageList = (id) => {
    //here id denotes the bloc id so will get village list of that state
    HTTPService(
      "GET",
      UrlConstants.base_url + UrlConstants.get_Village + "?block_id=" + id,
      "",
      false,
      true,
      getTokenLocal()
    )
      .then((response) => {
        setVillages(response?.data);
        setPGVillage(response?.data);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          navigate("/login");
          callToast("Session expired, please login again!", "success", true);
        }
      });
  };
  const handleAddNewFPO = async () => {
    //add new fpo form
    callLoader(true);
    setErrorMessage({});
    const trimmedPg = pg?.map((item) => ({
      ...item,
      pg_name: item.pg_name.trim(),
    }));

    let data = {
      name: fpoName.trim(),
      related_orgs: riName,
      village_id: !isEdit ? geography.village : geographyID.village,
      products_enabled: order ? "True" : "False",
      services_enabled: procurements ? "True" : "False",
      share_price: sharePrice ? sharePrice.trim() : "",
      president_name: presidentName.trim(),
      president_phone: presidentNumber ? "+91" + presidentNumber.trim() : "",
      president_gender: presidentGender,
      ceo_phone: ceoNumber ? "+91" + ceoNumber.trim() : "",
      ceo_name: ceoName ? ceoName.trim() : "",
      ceo_gender: ceoGender ? ceoGender : "",
      pg_data: JSON.stringify(trimmedPg),
    };
    if (typeof incorpCertificate !== "string" && incorpCertificate !== null) {
      data["certificate_of_registration"] = incorpCertificate;
    }
    const chronologicalCertificates = [...certificates].reverse();

    if (chronologicalCertificates?.length > 0) {
      chronologicalCertificates.forEach((cert) => {
        data[cert.name] = cert.file; // Using the certificate name as the key and the file as the value
      });
    }
    if (deletedCertificates?.length) {
      data["deleted_certificates"] = JSON.stringify(deletedCertificates);
    }

    let url;
    if (isEdit) {
      url =
        UrlConstants.base_url +
        UrlConstants.edit_FPC +
        selectedFPO +
        "/edit_a_fpc/";
    } else {
      url = UrlConstants.base_url + UrlConstants.add_FPC;
    }
    return await HTTPService("POST", url, data, true, true, getTokenLocal())
      .then((response) => {
        console.log(response);
        if (response?.status === 200) {
          handleClearForm();
          navigate("/FPOManagement");
          callLoader(false);
          if (isEdit) {
            setTabValue("1");
            if (getRoleLocal() === "ri_user" || getRoleLocal() === "dg_user") {
              setSelectedState("");
              setSelectedFPO("");
            }
          }
          callToast(
            !isEdit ? "FPO added successfully!" : "FPO updated successfully!",
            "success",
            true
          );
        } else {
          callToast("something went wrong", "error", true);
        }
      })
      .catch(async (e) => {
        callLoader(false);
        if (e?.response?.data?.name) {
          setErrorMessage((prevErrors) => ({
            ...prevErrors,
            fpoName: e?.response?.data?.name,
          }));
          scrollToError(fpoNameRef);
        } else if (e?.response?.data?.share_price) {
          setErrorMessage((prevErrors) => ({
            ...prevErrors,
            sharePrice: e?.response?.data?.share_price,
          }));
          scrollToError(sharePriceRef);
        } else if (e?.response?.data?.certificate_of_registration) {
          setErrorMessage((prevErrors) => ({
            ...prevErrors,
            incorpFile: e?.response?.data?.certificate_of_registration,
          }));
          scrollToError(incorpFileRef);
        } else if (e?.response?.data?.credit_guarantee_certificate) {
          setErrorMessage((prevErrors) => ({
            ...prevErrors,
            certificate: e?.response?.data?.credit_guarantee_certificate,
          }));
          scrollToError(certificateFileRef);
        } else if (e?.response?.data?.president_name) {
          setErrorMessage((prevErrors) => ({
            ...prevErrors,
            presidentName: e?.response?.data?.president_name,
          }));
          scrollToError(presidentNameRef);
        } else if (e?.response?.data?.president_phone) {
          setErrorMessage((prevErrors) => ({
            ...prevErrors,
            presidentNumber: e?.response?.data?.president_phone,
          }));
          scrollToError(presidentNumRef);
        } else if (e?.response?.data?.ceo_phone) {
          setErrorMessage((prevErrors) => ({
            ...prevErrors,
            ceoNumber: e?.response?.data?.ceo_phone,
          }));
          scrollToError(ceoNumberRef);
        } else if (e?.response?.data?.ceo_name) {
          setErrorMessage((prevErrors) => ({
            ...prevErrors,
            ceoName: e?.response?.data?.ceo_name,
          }));
          scrollToError(ceoNameRef);
        } else if (e?.response?.data?.ceo_gender) {
          setErrorMessage((prevErrors) => ({
            ...prevErrors,
            ceoGender: e?.response?.data?.ceo_gender,
          }));
          scrollToError(ceoNameRef);
        } else if (e?.response?.data?.other_certificates) {
          setErrorMessage((prevErrors) => ({
            ...prevErrors,
            certificate: e?.response?.data?.other_certificates,
          }));
          scrollToError(ceoNameRef);
        } else if (e?.response?.data?.pg_name) {
          callToast(e?.response?.data?.pg_name, "error", true);
        } else if (e?.response?.data?.error) {
          callToast(e?.response?.data?.error, "error", true);
        } else if (e?.response?.status === 401 || e?.response?.status === 403) {
          navigate("/login");
          callToast("Session expired, please login again!", "success", true);
        } else {
          callToast("something went wrong", "error", true);
        }
      });
  };
  const getFpcDetail = async () => {
    try {
      callLoader(true);

      const response = await HTTPService(
        "GET",
        UrlConstants.base_url +
          UrlConstants.get_FPC_detail +
          "?fpc_id=" +
          selectedFPO,
        "",
        false,
        true,
        getTokenLocal()
      );

      console.log(response?.data);
      setFpoID(response?.data?.id);
      setFpoName(response?.data?.name);
      setRiName(response?.data?.ri_name);
      setRiId(response?.data?.ri_id);
      setPg(response?.data?.pg_data);
      setSharePrice(response?.data?.share_price);
      setOtherCertificates(response?.data?.other_certificates);
      setOtherCeritificatesId(
        response?.data?.other_certificates.map((cert) => cert.id)
      );
      if (response?.data?.products_enabled === true) {
        setOrder(true);
      } else {
        setOrder(false);
      }
      if (response?.data?.services_enabled === true) {
        setProcurements(true);
      } else {
        setProcurements(false);
      }
      if (
        response?.data?.credit_guarantee_certificate ===
        (undefined || null || "")
      ) {
        setIncorpCertificate(null);
      } else {
        setIncorpCertificate(response?.data?.certificate_of_registration);
      }
      setGeograpy((prev) => ({
        ...prev,
        state: response?.data?.village?.state_en,
      }));
      setGeograpy((prev) => ({
        ...prev,
        district: response?.data?.village?.district_en,
      }));
      setGeograpy((prev) => ({
        ...prev,
        block: response?.data?.village?.block_en,
      }));
      setGeograpy((prev) => ({
        ...prev,
        village: response?.data?.village?.village_en,
      }));
      setGeograpyID((prev) => ({
        ...prev,
        state: response?.data?.village?.state_id,
      }));
      setGeograpyID((prev) => ({
        ...prev,
        district: response?.data?.village?.district_id,
      }));
      setGeograpyID((prev) => ({
        ...prev,
        block: response?.data?.village?.block_id,
      }));
      setGeograpyID((prev) => ({
        ...prev,
        village: response?.data?.village?.id,
      }));

      const presidentName = response?.data?.board_members.find(
        (member) => member?.role === "fpc_president"
      );
      if (presidentName) {
        setPresidentName(presidentName.first_name);
      }
      const presidentNumber = response?.data?.board_members.find(
        (member) => member?.role === "fpc_president"
      )?.phone;

      if (presidentNumber) {
        const cleanedPresidentNumber = presidentNumber.replace(/^(\+91)?/, "");
        const updatedPresidentNumber = cleanedPresidentNumber;

        setPresidentNumber(updatedPresidentNumber);
      }
      const presidentGender = response?.data?.board_members.find(
        (member) => member?.role === "fpc_president"
      )?.gender;
      if (presidentGender !== "NA") {
        setPresidentGender(presidentGender);
      } else {
        setPresidentGender("");
      }

      const ceoName = response?.data?.board_members.find(
        (member) => member?.role === "CEO"
      );
      if (ceoName && ceoName.first_name !== null && ceoName.first_name !== "") {
        setCeoName(ceoName.first_name);
      }
      const ceoNumber = response?.data?.board_members.find(
        (member) => member?.role === "CEO"
      )?.phone;

      if (ceoNumber && ceoNumber !== null && ceoNumber !== "") {
        const cleanedceoNumber = ceoNumber.replace(/^(\+91)?/, "");
        const updatedceoNumber = cleanedceoNumber;

        setCeoNumber(updatedceoNumber);
      }
      const ceoGender = response?.data?.board_members.find(
        (member) => member?.role === "CEO"
      )?.gender;
      if (ceoGender !== "NA") {
        setCeoGender(ceoGender);
      } else {
        setCeoGender("");
      }

      if (response?.data?.pg_data && response?.data?.pg_data.length > 0) {
        const pgData = response?.data?.pg_data.map((item) => ({
          pg_name: item?.pg_name,
          pg_village_id: item?.pg_village_id,
          pg_id: item?.pg_id,
          is_deleted: false,
        }));
        setPg(pgData);
      } else {
        // Set default value for pg if pg_data is empty
        setPg([
          {
            pg_name: "", // Set to default value
            pg_village_id: "", // Set to default value
            pg_id: undefined,
            is_deleted: false,
          },
        ]);
      }
      // Get sizes of other certificates
      const getOtherCertificateSizes = async () => {
        const sizes = await Promise.all(
          response?.data?.other_certificates.map(async (cert) => {
            try {
              const headResponse = await axios.head(cert.other_certificate);
              return {
                id: cert.id,
                size: headResponse.headers["content-length"],
              };
            } catch (error) {
              console.error(
                `Error fetching size for ${cert.other_certificate}:`,
                error
              );
              return {
                id: cert.id,
                size: null,
              };
            }
          })
        );
        return sizes;
      };

      const otherCertificateSizes = await getOtherCertificateSizes();
      setOtherCertificateSizes(otherCertificateSizes);
      console.log("otherCertificateSizes", otherCertificateSizes);
      callLoader(false);
    } catch (e) {
      callLoader(false);
      if (e?.response?.status === 401 || e?.response?.status === 403) {
        navigate("/login");
        callToast("Session expired, please login again!", "success", true);
      } else {
        callToast("something went wrong", "error", true);
      }
    }
  };
  const totalCertificates = certificates?.length + otherCertificates?.length;

  const handleFormSubmit = () => {
    const visiblePgItems = pg?.filter((item) => !item?.is_deleted);
    if (visiblePgItems?.length === 0) {
      // All items are hidden, proceed with save
      handleAddNewFPO();
    } else {
      // Check for duplicate names only for visible items
      const enteredPgNames = visiblePgItems.map((item) =>
        item.pg_name.trim().toLowerCase()
      );
      const lastEnteredPgName = enteredPgNames[enteredPgNames?.length - 1];
      const isDuplicate = enteredPgNames
        .slice(0, -1)
        .includes(lastEnteredPgName);
      const anyDuplicate = enteredPgNames
        .slice(0, -1)
        .some((name, index) => enteredPgNames.indexOf(name) !== index);

      if (isDuplicate || anyDuplicate) {
        callToast("Same Producer group name cannot be added", "error", true);
      } else {
        handleAddNewFPO();
      }
    }
  };

  useEffect(() => {
    getRiList();
    getStateList();
  }, []);
  useEffect(() => {
    //passing state id while getting district list
    if (!isEdit) {
      if (geography.state) {
        getDistrict(geography.state);
      }
    }
  }, [geography.state]);
  useEffect(() => {
    //passing district id while getting block list
    if (!isEdit) {
      if (geography.district) {
        getBlockList(geography.district);
      }
    }
  }, [geography.district]);
  useEffect(() => {
    //passing block id while getting village list
    if (!isEdit) {
      if (geography.block) {
        getVillageList(geography.block);
      }
    }
  }, [geography.block]);

  useEffect(() => {
    if (isEdit) {
      if (geographyID.block) {
        getVillageList(geographyID.block);
      }
    }
  }, [geographyID.block]);

  useEffect(() => {
    if (isEdit) {
      getFpcDetail();
    }
  }, [selectedFPO]);

  function isLastItemWithUndeleted(array, currentIndex) {
    for (let i = currentIndex + 1; i < array?.length; i++) {
      if (!array[i]?.is_deleted) {
        return false; // There is another undeleted item after the current one
      }
    }
    return true; // No undeleted item found after the current one
  }
  useEffect(() => {
    //to preview the file while uploading
    if (!isEdit) {
      if (!incorpCertificate) {
        setPreview(undefined);
        return;
      }
      const objectUrl = URL.createObjectURL(incorpCertificate);
      setPreview(objectUrl);
      setErrorMessage({ incorpFile: null });

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      if (!incorpCertificate) {
        setPreview(undefined);
        return;
      }
      setPreview(incorpCertificate);
      setErrorMessage({ incorpFile: null });
    }
  }, [incorpCertificate]);

  useEffect(() => {
    if (!certificates || certificates?.length === 0) {
      setCertificatePreview([]);
      return;
    }

    const previews = certificates.map((cert) => {
      console.log(
        "Creating Object URL for:",
        cert.file ? cert.file.name : cert
      );
      return cert.file ? URL.createObjectURL(cert.file) : cert;
    });

    setCertificatePreview(previews);

    // Cleanup function to revoke object URLs
    return () => {
      previews.forEach((preview) => {
        if (typeof preview === "string" && !preview.includes("blob:")) {
          console.log("Skipping cleanup for URL:", preview);
        } else {
          console.log("Revoking Object URL:", preview);
          URL.revokeObjectURL(preview);
        }
      });
    };
  }, [certificates]);
  useEffect(() => {
    // Phone Number
    if (fpoNameRef.current && errorMessage.fpoName) {
      scrollToError(fpoNameRef, 40);
    }
    if (sharePriceRef.current && errorMessage.sharePrice) {
      scrollToError(sharePriceRef, 40);
    }
    if (incorpFileRef.current && errorMessage.incorpFile) {
      scrollToError(incorpFileRef, 40);
    }
    if (certificateFileRef.current && errorMessage.certificate) {
      scrollToError(certificateFileRef, 420);
    }
    if (presidentNameRef.current && errorMessage.presidentName) {
      scrollToError(presidentName, 40);
    }
    if (presidentNumRef.current && errorMessage.presidentNumber) {
      scrollToError(presidentNumRef, 40);
    }
    if (ceoNameRef.current && errorMessage.ceoName) {
      scrollToError(ceoNameRef, 40);
    }
    if (ceoNumberRef.current && errorMessage.ceoNumber) {
      scrollToError(ceoNumberRef, 40);
    }
    if (ceoGenderRef.current && errorMessage.ceoGender) {
      scrollToError(ceoGenderRef, 40);
    }
  }, [
    errorMessage.fpoName,
    errorMessage.sharePrice,
    errorMessage.incorpFile,
    errorMessage.certificate,
    errorMessage.presidentName,
    errorMessage.presidentNumber,
    errorMessage.ceoName,
    errorMessage.ceoNumber,
    errorMessage.ceoGender,
  ]);

  useEffect(() => {
    // Update the certificate count whenever otherCertificates or certificates change
    setCertificateCount(otherCertificates.length + certificates.length);
  }, [otherCertificates, certificates]);

  useEffect(() => {
    setCertCount(certificates.length);
  }, [certificates]);

  return (
    <>
      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          disableWindowBlurListener={true}
          sx={{
            width: "860px",
            marginTop: "50px",
            height: "50px",
            color: "#212B36",
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          action={action}
        >
          <Alert
            severity={"warning"}
            sx={{
              width: "860px",
              textAlign: "left",
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontSize: "14px",
              backgroundColor: "#FFC54E",
              color: "#212B36",
              borderRadius: "8px",
              "& .MuiAlert-icon": {
                color: "#212B36",
              },
            }}
            action={action}
          >
            {"Are you sure, you want to proceed with this action ?"}
          </Alert>
        </Snackbar>
      )}
      <div>
        <Row>
          {" "}
          <Col xs={6} sm={6} md={6} lg={6}>
            {!isEdit ? (
              <Button
                sx={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  },
                }}
                variant="text"
                className={`${LocalStyle.backButton}`}
                onClick={handleBackButtonClick}
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Typography className={LocalStyle.titleHead}>
              {isEdit ? "Edit profile (FPO)" : "Add new FPO details"}
            </Typography>
            {!isEdit ? (
              <Typography className={LocalStyle.subTitle}>
                {"FPO profile"}
              </Typography>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <TextField
              label="FPO name"
              name="fpoName"
              value={fpoName}
              inputProps={{ maxLength: 1000 }}
              disabled={isEdit ? true : false}
              onKeyDown={handleFpoSpace}
              onChange={(e) => {
                const inputValue = e.target.value;
                const alphabetsRegex = /^[a-zA-Z\s]*$/;

                if (alphabetsRegex.test(inputValue)) {
                  setFpoName(inputValue);
                  setErrorMessage({
                    fpoName: null,
                  });
                }
              }}
              style={inputStyle}
              required
              className={LocalStyle.textField}
              id="fponame"
              error={errorMessage.fpoName ? true : false}
              helperText={errorMessage.fpoName ? errorMessage.fpoName : ""}
              ref={fpoNameRef}
            />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <FormControl
              className={LocalStyle.textField}
              variant="outlined"
              fullWidth
              required
              style={inputStyle}
            >
              <InputLabel id="demo-multiple-name-label">RI name</InputLabel>
              {!isEdit ? (
                <Select
                  style={{
                    textAlign: "left",
                  }}
                  labelId="RIName"
                  id="riname"
                  label="RI name "
                  fullWidth
                  required
                  value={riName}
                  onChange={(e) => setRiName(e.target.value)}
                >
                  {riNames?.map((riName, index) => {
                    return (
                      <MenuItem
                        className="fontFamily"
                        key={`riname-${riName.id}-${index}`}
                        value={riName.id}
                      >
                        {riName.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : (
                <Select
                  style={{
                    textAlign: "left",
                  }}
                  disabled={isEdit ? true : false}
                  labelId="RiName"
                  id="riname"
                  label="RI Name "
                  fullWidth
                  required
                  value={riId}
                >
                  <MenuItem className="fontFamily" value={riId}>
                    {riName}
                  </MenuItem>
                </Select>
              )}
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <FormControl
              className={LocalStyle.textField}
              variant="outlined"
              fullWidth
              style={inputStyle}
              required
              disabled={isEdit ? true : false}
            >
              <InputLabel id="demo-multiple-name-label">State</InputLabel>
              {!isEdit ? (
                <Select
                  style={{
                    textAlign: "left",
                  }}
                  labelId="State"
                  id="state"
                  label="State"
                  fullWidth
                  required
                  value={geography?.state}
                  onChange={handleStateChange}
                >
                  {states?.map((state, index) => {
                    return (
                      <MenuItem
                        className="fontFamily"
                        key={`state-${state.id}-${index}`}
                        value={state.id}
                      >
                        {state.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : (
                <Select
                  style={{
                    textAlign: "left",
                  }}
                  labelId="State"
                  id="state"
                  label="State"
                  fullWidth
                  required
                  value={geographyID?.state}
                >
                  <MenuItem className="fontFamily" value={geographyID?.state}>
                    {geography.state}
                  </MenuItem>
                </Select>
              )}
            </FormControl>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <FormControl
              required
              className={LocalStyle.textField}
              variant="outlined"
              fullWidth
              style={inputStyle}
              disabled={isEdit ? true : false}
            >
              <InputLabel id="demo-multiple-name-label">District</InputLabel>
              {!isEdit ? (
                <Select
                  style={{
                    textAlign: "left",
                  }}
                  labelId="District"
                  id="District"
                  label="District "
                  fullWidth
                  required
                  value={geography?.district}
                  onChange={handleDistrictChange}
                >
                  {districts?.map((district, index) => {
                    return (
                      <MenuItem
                        className="fontFamily"
                        id={`district-${district + index}`}
                        value={district.id}
                      >
                        {district.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : (
                <Select
                  style={{
                    textAlign: "left",
                  }}
                  labelId="District"
                  id="District"
                  label="District "
                  fullWidth
                  required
                  value={geographyID?.district}
                >
                  <MenuItem
                    className="fontFamily"
                    value={geographyID?.district}
                  >
                    {geography.district}
                  </MenuItem>
                </Select>
              )}
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            {" "}
            <FormControl
              className={LocalStyle.textField}
              required
              variant="outlined"
              fullWidth
              style={inputStyle}
              disabled={isEdit ? true : false}
            >
              <InputLabel id="demo-multiple-name-label">Block</InputLabel>
              {!isEdit ? (
                <Select
                  style={{
                    textAlign: "left",
                  }}
                  labelId="block"
                  id="block"
                  label="block "
                  fullWidth
                  required
                  value={geography?.block}
                  onChange={handleBlockChange}
                >
                  {blocks?.map((block, index) => {
                    return (
                      <MenuItem
                        className="fontFamily"
                        id={`block-${block + index}`}
                        value={block.id}
                      >
                        {block.name}
                      </MenuItem>
                    );
                    // })
                  })}
                </Select>
              ) : (
                <Select
                  style={{
                    textAlign: "left",
                  }}
                  labelId="block"
                  id="block"
                  label="block "
                  fullWidth
                  required
                  value={geographyID?.block}
                >
                  <MenuItem className="fontFamily" value={geographyID?.block}>
                    {geography?.block}
                  </MenuItem>
                </Select>
              )}
            </FormControl>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            {" "}
            <FormControl
              required
              className={LocalStyle.textField}
              variant="outlined"
              fullWidth
              style={inputStyle}
            >
              <InputLabel id="demo-multiple-name-label">Village</InputLabel>
              {!isEdit ? (
                <Select
                  style={{
                    textAlign: "left",
                  }}
                  labelId="Village"
                  id="Village"
                  label="Village"
                  fullWidth
                  required
                  value={geography?.village}
                  onChange={handleVillageChange}
                >
                  {villages?.map((village, index) => {
                    return (
                      <MenuItem
                        className="fontFamily"
                        id={`village-${village + index}`}
                        value={village.id}
                      >
                        {village.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : (
                <Select
                  style={{
                    textAlign: "left",
                  }}
                  labelId="Village"
                  id="Village"
                  label="Village"
                  fullWidth
                  required
                  value={geographyID?.village}
                  onChange={handleVillageChangeEdit}
                >
                  {villages?.map((village, index) => {
                    return (
                      <MenuItem
                        className="fontFamily"
                        id={`village-${village + index}`}
                        value={village.id ? village.id : geographyID?.village}
                      >
                        {village.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Typography className={LocalStyle.subTitle}>
              {"Existing businesses of the FPO"}
              <span style={{ color: "red", marginLeft: "5px" }}>*</span>
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6} className={LocalStyle.checkBox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={order}
                    onChange={(e) => setOrder(e.target.checked)}
                  />
                }
                sx={{
                  paddingLeft: "15px",
                  fontFamily: "Montserrat",
                }}
                label="Orders"
              />
            </FormGroup>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} className={LocalStyle.checkBox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={procurements}
                    onChange={(e) => setProcurements(e.target.checked)}
                  />
                }
                sx={{
                  paddingLeft: "15px",
                  fontFamily: "Montserrat !important",
                }}
                label="Procurements"
                className="checkBox"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Typography className={LocalStyle.subTitle}>
              {"Authorities"}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Typography className={LocalStyle.subHead}>
              {"President details"}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <TextField
              label="President name    "
              name="President name"
              value={presidentName}
              onKeyDown={handlePresidentSpace}
              inputProps={{ maxLength: 255 }}
              onChange={(e) => {
                const inputValue = e.target.value;
                const alphabetsRegex = /^[a-zA-Z\s]*$/;

                if (alphabetsRegex.test(inputValue)) {
                  setPresidentName(inputValue);
                  setErrorMessage({
                    presidentName: null,
                  });
                }
              }}
              style={inputStyle}
              required
              className={LocalStyle.textField}
              error={errorMessage.presidentName ? true : false}
              helperText={
                errorMessage.presidentName ? errorMessage.presidentName : ""
              }
              ref={presidentNameRef}
            />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <TextField
              label="President mobile number"
              name="President mobile number"
              value={presidentNumber}
              sx={{
                "& label": {
                  fontSize: "14.9px",
                },
              }}
              onChange={(e) => {
                const { value } = e.target;
                const regex = /^[0-9]*$/;
                if (value?.length < 10) {
                  setPresidentNumberErrorMessage(
                    "Enter 10 digit valid phone number"
                  );
                } else {
                  setErrorMessage({ presidentNumber: null });
                  setPresidentNumberErrorMessage("");
                }

                if (regex?.test(value) && value?.length <= 10) {
                  setPresidentNumber(value);
                  setErrorMessage({ presidentNumber: null });
                }
              }}
              inputProps={{ maxLength: 10 }}
              style={inputStyle}
              required
              className={LocalStyle.textField}
              error={
                errorMessage.presidentNumber || presidentNumberErrorMessage
                  ? true
                  : false
              }
              helperText={
                errorMessage.presidentNumber
                  ? errorMessage.presidentNumber
                  : presidentNumberErrorMessage
              }
              ref={presidentNumRef}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <FormControl
              required
              className={LocalStyle.textField}
              variant="outlined"
              fullWidth
              style={inputStyle}
            >
              <InputLabel
                id="demo-multiple-name-label"
                label="demo-multiple-name-label"
                variant="outlined"
              >
                Select gender
              </InputLabel>
              <Select
                style={{
                  textAlign: "left",
                }}
                labelId="gender"
                id="gender"
                label="Select gender"
                fullWidth
                required
                value={presidentGender}
                onChange={(e) => setPresidentGender(e.target.value)}
                variant="outlined"
                addlabel="demo-multiple-name-label"
              >
                {genders?.map((gender, index) => {
                  return (
                    <MenuItem
                      className="fontFamily"
                      id={`gender-${gender + index}`}
                      value={gender.value}
                    >
                      {gender.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Typography className={LocalStyle.subHead}>
              {"CEO details"}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <TextField
              label="CEO name"
              name="CEO name"
              value={ceoName}
              onKeyDown={handleCEONameSpace}
              inputProps={{ maxLength: 255 }}
              onChange={(e) => {
                const inputValue = e.target.value;
                const alphabetsRegex = /^[a-zA-Z\s]*$/;

                if (alphabetsRegex.test(inputValue)) {
                  setCeoName(inputValue);
                  setErrorMessage({
                    ceoName: null,
                  });
                }
              }}
              style={inputStyle}
              className={LocalStyle.textField}
              error={errorMessage.ceoName ? true : false}
              helperText={errorMessage.ceoName ? errorMessage.ceoName : ""}
              ref={ceoNameRef}
              sx={{
                "& label": {
                  fontSize: "14.9px",
                },
              }}
            />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <TextField
              label="CEO mobile number "
              name="CEO mobile number"
              variant="outlined"
              value={ceoNumber}
              inputProps={{ maxLength: 10 }}
              onChange={(e) => {
                const { value } = e.target;
                const regex = /^[0-9]*$/;
                if (value?.length === 0) {
                  setCeoNumberErrorMessage("");
                } else if (value?.length < 10) {
                  setCeoNumberErrorMessage("Enter 10 digit valid phone number");
                } else {
                  setErrorMessage({ ceoNumber: null });
                  setCeoNumberErrorMessage("");
                }

                if (value?.length <= 10 && regex.test(value)) {
                  setCeoNumber(value);
                  setErrorMessage({ ceoNumber: null });
                }
              }}
              sx={{
                "& label": {
                  fontSize: "14.6px",
                },
              }}
              style={inputStyle}
              className={LocalStyle.textField}
              error={
                errorMessage.ceoNumber || ceoNumberErrorMessage ? true : false
              }
              helperText={
                errorMessage.ceoNumber
                  ? errorMessage.ceoNumber
                  : ceoNumberErrorMessage
              }
              ref={ceoNumberRef}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <FormControl
              className={LocalStyle.textField}
              variant="outlined"
              fullWidth
              style={inputStyle}
              error={errorMessage.ceoGender ? true : false}
            >
              <InputLabel
                id="demo-multiple-name-label"
                label="demo-multiple-name-label"
                variant="outlined"
              >
                Select gender
              </InputLabel>
              <Select
                style={{
                  textAlign: "left",
                }}
                labelId="gender"
                id="gender"
                label="Select gender"
                fullWidth
                value={ceoGender}
                onChange={(e) => {
                  setCeoGender(e.target.value);
                  setErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    ceoGender: null,
                  }));
                }}
                variant="outlined"
                addlabel="demo-multiple-name-label"
              >
                {!ceoName && (!ceoNumber || ceoNumber?.length !== 10) ? (
                  <MenuItem className="fontFamily" value={""}>
                    {"None"}
                  </MenuItem>
                ) : null}
                {genders?.map((gender, index) => {
                  return (
                    <MenuItem
                      className="fontFamily"
                      id={`gender-${gender + index}`}
                      value={gender.value}
                    >
                      {gender.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {errorMessage.ceoGender && (
                <FormHelperText>{errorMessage.ceoGender}</FormHelperText>
              )}
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Typography
              className={LocalStyle.subTitle}
              style={{ marginBottom: "revert" }}
            >
              {" Upload certificates"}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <TabContext value={cerTab}>
              <Box
                sx={{
                  borderBottom: "1px solid #3D4A52",
                  borderColor: "#3D4A52",
                }}
              >
                <TabList
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                  className="tabStyle"
                  sx={{ maxWidth: "1108px", width: "100%" }}
                >
                  <Tab
                    style={{
                      color: cerTab === "1" ? "#00AB55" : "#3D4A52",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: cerTab === "1" ? "600" : "400",
                      textTransform: "none",
                      height: "48px",
                      padding: "inherit",
                      gap: "0px",
                      border: "0px 0px 2px 0px",
                      opacity: "0px",
                    }}
                    label={
                      <span>
                        FPO incorporation certificate{" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    value="1"
                    className="tabStyle"
                  />
                  <Tab
                    style={{
                      color: cerTab === "2" ? "#00AB55" : "#3D4A52",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: cerTab === "2" ? "600" : "400",
                      textTransform: "none",
                      height: "48px",
                      padding: "13px 0px 12px 0px",
                      gap: "0px",
                      marginLeft: "50px",
                    }}
                    label="Other certificates"
                    value="2"
                  />
                </TabList>
              </Box>

              <TabPanel
                value="1"
                sx={{ paddingLeft: "0px", paddingTop: "30px" }}
              >
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <FileUploaderMain
                      key={key}
                      isMultiple={false}
                      texts={
                        <>
                          <Row>
                            <Row
                              className={`${style.browseStyle} ${LocalStyle.margin5}`}
                            >
                              {"Drag and drop your file here"}
                            </Row>
                            <Row
                              className={`${style.browseStyle} ${LocalStyle.margin5}`}
                            >
                              {"or"}
                            </Row>
                            <Row style={{ justifyContent: "center" }}>
                              <Button
                                className={`${style.browseBtn} ${LocalStyle.margin5}`}
                              >
                                Browse file
                              </Button>
                            </Row>
                            <Row className={LocalStyle.note}>
                              {
                                "Note: Only PDF and JPEG files are supported, should have a file size limit of 5 MBs."
                              }
                            </Row>
                          </Row>
                        </>
                      }
                      fileTypes={fileTypes}
                      handleChange={handleUploadincorp}
                      maxSize={5}
                      setSizeError={() =>
                        setErrorMessage((prevErrors) => ({
                          ...prevErrors,
                          incorpFile: "Maximum file size allowed is 5MB",
                        }))
                      }
                      id="upload-policy-file"
                    />
                    <span
                      style={{
                        color: "#d32f2f",
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        lineHeight: "1.66px",
                        letterSpacing: "0.03333em",
                      }}
                      ref={incorpFileRef}
                    >
                      {errorMessage.incorpFile ? errorMessage.incorpFile : ""}
                    </span>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <div
                      className={
                        LocalStyle.fileTextSize + " " + LocalStyle.text_left
                      }
                      style={{ marginBottom: "20px" }}
                    >
                      {incorpCertificate && (
                        <span>
                          Total uploaded certificates -
                          <span className={LocalStyle.certificateCountStyle}>
                            {` (${1})`}
                          </span>
                        </span>
                      )}
                    </div>
                    {incorpCertificate && (
                      <div className={LocalStyle.text_left}>
                        {incorpCertificate && (
                          <div className={LocalStyle.each_preview_policy}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                id="document-logo"
                                height={"54px"}
                                width={"54px"}
                                className={LocalStyle.document_upload_logo}
                                src={document_upload}
                              />

                              <span
                                id="file-preview"
                                className={
                                  LocalStyle.blue + " " + LocalStyle.link
                                }
                                onClick={() => window.open(preview)}
                                style={{ width: "100%" }}
                              >
                                {incorpCertificate && incorpCertificate.name
                                  ? incorpCertificate.name
                                  : typeof incorpCertificate === "string" &&
                                    fpoID
                                  ? incorpCertificate.includes(fpoID)
                                    ? incorpCertificate.split(`${fpoID}_`)[1]
                                    : ""
                                  : ""}
                              </span>
                            </div>
                            <CustomDeletePopper
                              anchorEl={anchorEl}
                              handleDelete={handleDeleteIncorp}
                              id={"delete-popper-id"}
                              open={open}
                              closePopper={closePopper}
                            />
                            <ClearIcon
                              onClick={handleDeletePopper}
                              style={{ cursor: "pointer" }}
                              fontSize="small"
                              id="cancel-file"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </TabPanel>

              <TabPanel
                value="2"
                sx={{ paddingLeft: "0px", paddingTop: "30px" }}
              >
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <TextField
                      label="Enter the certificate name "
                      name="Enter the certificate name "
                      required
                      variant="outlined"
                      value={certificateName}
                      onKeyDown={handleCertiticateSpace}
                      inputProps={{ maxLength: 50 }}
                      sx={{
                        "& label": {
                          fontSize: "14.6px",
                        },
                      }}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const alphabetsRegex = /^[a-zA-Z\s]*$/;
                        if (alphabetsRegex.test(inputValue)) {
                          setCertificateName(e.target.value);
                          setErrorMessage((prevErrors) => ({
                            ...prevErrors,
                            certificateName: null,
                          }));
                          setErrorMessage((prevErrors) => ({
                            ...prevErrors,
                            certificate: null,
                          }));
                        }
                        setOpen(false);
                      }}
                      style={inputStyle}
                      className={LocalStyle.textField}
                      disabled={
                        isEdit
                          ? totalCertificates >= 10
                          : certificates.length >= 10
                      }
                      error={errorMessage.certificateName ? true : false}
                      helperText={
                        errorMessage.certificateName
                          ? errorMessage.certificateName
                          : ""
                      }
                    />
                    <hr style={{ marginBottom: "40px", marginTop: "30px" }} />
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Typography className={LocalStyle.lastUpdate}>
                      <InfoIcon
                        sx={{
                          color: "#6D6D6D",
                          marginRight: "5px",
                        }}
                      />
                      You can upload a maximum of 10 certificates.
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <FileUploaderMain
                      key={key}
                      isMultiple={false}
                      texts={
                        <>
                          <Row>
                            <Row
                              className={`${style.browseStyle} ${LocalStyle.margin5}`}
                            >
                              {"Drag and drop your file here"}
                            </Row>
                            <Row
                              className={`${style.browseStyle} ${LocalStyle.margin5}`}
                            >
                              {"or"}
                            </Row>
                            <Row style={{ justifyContent: "center" }}>
                              <Button
                                className={`${style.browseBtn} ${LocalStyle.margin5}`}
                                disabled={certificateName ? false : true}
                              >
                                Browse file
                              </Button>
                            </Row>
                            <Row className={LocalStyle.note}>
                              {
                                "Note: Only PDF and JPEG files are supported, should have a file size limit of 5 MBs."
                              }
                            </Row>
                          </Row>
                        </>
                      }
                      fileTypes={fileTypes}
                      handleChange={handleUploadGuarantee}
                      maxSize={5}
                      setSizeError={() =>
                        setErrorMessage((prevErrors) => ({
                          ...prevErrors,
                          certificate: "Maximum file size allowed is 5MB",
                        }))
                      }
                      id="upload-policy-file"
                      disabled={certificateName ? false : true}
                    />
                    <span
                      style={{
                        color: "#d32f2f",
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                      ref={certificateFileRef}
                    >
                      {errorMessage.certificate ? errorMessage.certificate : ""}
                    </span>
                  </Col>
                  {isEdit ? (
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <div
                        className={
                          LocalStyle.fileTextSize + " " + LocalStyle.text_left
                        }
                        style={{ marginBottom: "20px" }}
                      >
                        {(otherCertificates?.length > 0 ||
                          certificates?.length > 0) && (
                          <span>
                            Total uploaded certificates -
                            <span className={LocalStyle.certificateCountStyle}>
                              {` (${certificateCount})`}
                            </span>
                          </span>
                        )}
                      </div>
                      <div
                        ref={scrollContainerRef}
                        style={{
                          maxHeight: "245px",
                          overflowY:
                            otherCertificates?.length > 2 ||
                            certificates?.length > 0
                              ? "auto"
                              : "visible",
                        }}
                      >
                        <>
                          {certificates.map((cert, index) => (
                            <>
                              <div
                                data-index={index}
                                key={index}
                                className={LocalStyle.text_left}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    id="file-preview"
                                    className={LocalStyle.certificateName}
                                  >
                                    {cert.name}
                                  </span>
                                </div>
                                <div className={LocalStyle.each_preview_policy}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      id="document-logo"
                                      height={"54px"}
                                      width={"54px"}
                                      className={
                                        LocalStyle.document_upload_logo
                                      }
                                      src={document_upload}
                                    />
                                    <span
                                      id="file-preview"
                                      className={
                                        LocalStyle.blue + " " + LocalStyle.link
                                      }
                                      onClick={() =>
                                        window.open(certificatePreview[index])
                                      }
                                      style={{ width: "100%" }}
                                    >
                                      {cert.file && cert.file.name ? (
                                        <>{cert.file.name}</>
                                      ) : typeof cert === "string" && fpoID ? (
                                        cert.includes(fpoID) ? (
                                          cert.split(`${fpoID}_`)[1]
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                    <span
                                      style={{
                                        textDecoration: "none",
                                        whiteSpace: "nowrap",
                                        marginLeft: "2px",
                                      }}
                                      className={LocalStyle.fileSize}
                                    >
                                      {" "}
                                      ({(cert.file.size / 1000000).toFixed(
                                        2
                                      )}{" "}
                                      MB)
                                    </span>
                                  </div>
                                  <ClearIcon
                                    onClick={(event) =>
                                      handleDeletePopperCertificates(
                                        event,
                                        index
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "15px",
                                    }}
                                    fontSize="small"
                                    id="cancel-file"
                                  />
                                </div>
                              </div>
                              <hr />
                            </>
                          ))}
                          {otherCertificates
                            .slice()
                            .reverse()
                            .map((cert, index) => {
                              const originalIndex =
                                otherCertificates.length - 1 - index;
                              const certSize = otherCertificateSizes.find(
                                (size) => size.id === cert.id
                              )?.size;
                              return (
                                <>
                                  <div
                                    data-index={originalIndex}
                                    key={index}
                                    className={LocalStyle.text_left}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        id="file-preview"
                                        className={LocalStyle.certificateName}
                                      >
                                        {cert.name}
                                      </span>
                                    </div>
                                    <div
                                      className={LocalStyle.each_preview_policy}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          id="document-logo"
                                          height={"54px"}
                                          width={"54px"}
                                          className={
                                            LocalStyle.document_upload_logo
                                          }
                                          src={document_upload}
                                        />
                                        <span
                                          id="file-preview"
                                          className={
                                            LocalStyle.blue +
                                            " " +
                                            LocalStyle.link
                                          }
                                          onClick={() =>
                                            window.open(cert.other_certificate)
                                          }
                                          style={{ width: "100%" }}
                                        >
                                          {typeof cert.other_certificate ===
                                            "string" &&
                                          otherCertificatesId.includes(cert.id)
                                            ? cert.other_certificate.split(
                                                `${cert.id}_`
                                              )[1]
                                            : ""}
                                        </span>
                                        {certSize ? (
                                          <span
                                            style={{
                                              textDecoration: "none",
                                              whiteSpace: "nowrap",
                                              marginLeft: "2px",
                                            }}
                                            className={LocalStyle.fileSize}
                                          >
                                            {` (${(certSize / 1000000).toFixed(
                                              2
                                            )} MB)`}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <ClearIcon
                                        onClick={(event) =>
                                          handleDeletePopperOtherCertificates(
                                            event,
                                            originalIndex
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "15px",
                                        }}
                                        fontSize="small"
                                        id="cancel-file"
                                      />
                                    </div>
                                  </div>
                                  <hr />
                                </>
                              );
                            })}
                          {anchorEl && (
                            <CustomDeletePopper
                              anchorEl={anchorEl}
                              handleDelete={
                                popperType === "certificates"
                                  ? handleDeleteCertificate
                                  : handleDeleteOtherCertificates
                              }
                              open={open}
                              closePopper={closePopper}
                            />
                          )}
                        </>
                      </div>
                    </Col>
                  ) : (
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <div
                        className={
                          LocalStyle.fileTextSize + " " + LocalStyle.text_left
                        }
                        style={{ marginBottom: "20px" }}
                      >
                        {certificates?.length > 0 && (
                          <span>
                            Total uploaded certificates -
                            <span className={LocalStyle.certificateCountStyle}>
                              {` (${certCount})`}
                            </span>
                          </span>
                        )}
                      </div>
                      <div
                        ref={scrollContainerRef}
                        style={{
                          maxHeight: "245px",
                          overflowY:
                            certificates?.length > 2 ? "auto" : "visible",
                        }}
                      >
                        <>
                          {certificates?.map((cert, index) => (
                            <>
                              <div
                                data-index={index}
                                key={index}
                                className={LocalStyle.text_left}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    id="file-preview"
                                    className={LocalStyle.certificateName}
                                  >
                                    {cert.name}
                                  </span>
                                </div>
                                <div className={LocalStyle.each_preview_policy}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      id="document-logo"
                                      height={"54px"}
                                      width={"54px"}
                                      className={
                                        LocalStyle.document_upload_logo
                                      }
                                      src={document_upload}
                                    />
                                    <span
                                      id="file-preview"
                                      className={
                                        LocalStyle.blue + " " + LocalStyle.link
                                      }
                                      onClick={() =>
                                        window.open(certificatePreview[index])
                                      }
                                      style={{ width: "100%" }}
                                    >
                                      {cert.file && cert.file.name ? (
                                        <>{cert.file.name}</>
                                      ) : typeof cert === "string" && fpoID ? (
                                        cert.includes(fpoID) ? (
                                          cert.split(`${fpoID}_`)[1]
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                    <span
                                      style={{
                                        textDecoration: "none",
                                        whiteSpace: "nowrap",
                                        marginLeft: "2px",
                                      }}
                                      className={LocalStyle.fileSize}
                                    >
                                      {" "}
                                      ({(cert.file.size / 1000000).toFixed(
                                        2
                                      )}{" "}
                                      MB)
                                    </span>
                                  </div>
                                  <ClearIcon
                                    onClick={(event) =>
                                      handleDeletePopperCertificates(
                                        event,
                                        index
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "15px",
                                    }}
                                    fontSize="small"
                                    id="cancel-file"
                                  />
                                </div>
                              </div>
                              <hr />
                            </>
                          ))}
                          {anchorEl && certificates && (
                            <CustomDeletePopper
                              anchorEl={anchorEl}
                              handleDelete={handleDeleteCertificate}
                              id={"delete-popper-id"}
                              open={open}
                              closePopper={closePopper}
                            />
                          )}
                        </>
                      </div>
                    </Col>
                  )}
                </Row>
              </TabPanel>
            </TabContext>
          </Col>
          <hr
            style={{ width: "1130px", marginLeft: "5px", marginTop: "40px" }}
          />
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Typography className={LocalStyle.subTitle}>
              {"Share price"}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <TextField
              label="Price per share in rupees"
              name="Price per share in rupees"
              value={sharePrice}
              onChange={(e) => {
                let value = e.target.value;

                value = value.replace(/[^\d.]/g, "");

                const validInput = /^\d{1,8}(?:\.\d{0,2})?$/.test(value);

                if (validInput || value === "") {
                  setSharePrice(value);
                  setErrorMessage({ sharePrice: null });
                }
              }}
              sx={{
                "& label": {
                  fontSize: "14.6px",
                },
              }}
              style={inputStyle}
              className={LocalStyle.textField}
              error={errorMessage.sharePrice ? true : false}
              helperText={
                errorMessage.sharePrice ? errorMessage.sharePrice : ""
              }
              ref={sharePriceRef}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Typography className={LocalStyle.subTitle}>
              {"Producer group details"}
            </Typography>
          </Col>
        </Row>
        {pg.map((pgItem, index) => (
          <>
            <Row
              key={index}
              hidden={pgItem.is_deleted == true && index != 0 ? true : false}
            >
              <Col xs={6} sm={6} md={6} lg={6}>
                <TextField
                  fullWidth
                  label="Producer group name"
                  inputProps={{ maxLength: 255 }}
                  value={pgItem.pg_name}
                  onChange={(e) => {
                    handlePgChange(
                      index,
                      "pg_name",
                      e.target.value.trimStart()
                    );
                  }}
                  style={inputStyle}
                  className={LocalStyle.textField}
                  sx={{
                    "& label": {
                      fontSize: "14.6px",
                    },
                  }}
                />
              </Col>
              <Col xs={6} sm={6} md={6} lg={6}>
                <FormControl
                  className={LocalStyle.textField}
                  variant="outlined"
                  fullWidth
                  style={inputStyle}
                >
                  <InputLabel
                    id="demo-multiple-name-label"
                    label="demo-multiple-name-label"
                  >
                    Producer group village
                  </InputLabel>
                  {isEdit ? (
                    <Select
                      style={{
                        textAlign: "left",
                      }}
                      labelId="Producer group village"
                      id="Producer group village"
                      label="Producer group village "
                      fullWidth
                      required
                      value={pgItem?.pg_village_id}
                      onChange={(e) => {
                        handlePgChange(index, "pg_village_id", e.target.value);
                      }}
                    >
                      {pg
                        .concat(pgVillage)
                        .filter(
                          (pgvillage) =>
                            pgvillage.name || pgvillage.pg_village_name
                        ) // Ensure non-empty names
                        .map((pgvillage, index) => (
                          <MenuItem
                            key={`village-${pgvillage.id}-${index}`}
                            className="fontFamily"
                            value={
                              pgvillage.pg_village_id
                                ? pgvillage.pg_village_id
                                : pgvillage.id
                            }
                          >
                            {pgvillage.name
                              ? pgvillage.name
                              : pgvillage.pg_village_name}
                          </MenuItem>
                        ))}
                    </Select>
                  ) : (
                    <Select
                      style={{
                        textAlign: "left",
                      }}
                      labelId="Producer group village"
                      id="Producer group village"
                      label="Producer group village"
                      fullWidth
                      required
                      value={pgItem?.pg_village_id}
                      onChange={(e) => {
                        handlePgChange(index, "pg_village_id", e.target.value);
                      }}
                    >
                      {pgVillage?.map((pgvillage, index) => {
                        return (
                          <MenuItem
                            className="fontFamily"
                            id={`village-${pgvillage + index}`}
                            value={pgvillage.id}
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {pgvillage.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </FormControl>
              </Col>
            </Row>
            <Row
              hidden={pgItem.is_deleted == true && index != 0 ? true : false}
            >
              <Col xs={6} sm={6} md={6} lg={6}></Col>
              <Col
                xs={6}
                sm={6}
                md={6}
                lg={6}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  flexDirection: "row",
                  paddingRight: "33px",
                }}
              >
                {index === 0 && (
                  <Button
                    className={LocalStyle.removeBtn}
                    onClick={
                      !isEdit
                        ? () => handleClearField(index)
                        : () => handleClearFieldEdit(index)
                    }
                  >
                    Remove
                  </Button>
                )}

                {index !== 0 && (
                  <Button
                    className={LocalStyle.removeBtn}
                    onClick={
                      !isEdit
                        ? () => handleDeleteField(index)
                        : () => handleClearFieldEdit(index)
                    }
                  >
                    Remove
                  </Button>
                )}
                {!isEdit
                  ? index === pg?.length - 1 &&
                    index < 49 && (
                      <Button
                        className={LocalStyle.addMoreBtn}
                        onClick={addNewPg}
                      >
                        + Add more Producer groups
                      </Button>
                    )
                  : isLastItemWithUndeleted(pg, index) &&
                    pg
                      ?.slice(0, index)
                      ?.filter((item) => item.is_deleted == false)?.length <
                      49 && (
                      <Button
                        className={LocalStyle.addMoreBtn}
                        onClick={addNewPg}
                      >
                        + Add more Producer groups
                      </Button>
                    )}
              </Col>
            </Row>
            <hr
              hidden={pgItem.is_deleted == true && index != 0 ? true : false}
              style={{ width: "1130px", marginLeft: "5px" }}
            />
          </>
        ))}

        <Row
          style={{
            marginTop: "50px",
            marginBottom: "100px",
          }}
        >
          <Col xs={6} sm={6} md={6} lg={6}>
            <Row>
              {" "}
              <Col xs={6} sm={6} md={6} lg={6}>
                {!isEdit ? (
                  <Button
                    sx={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      "&:hover": {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                      },
                    }}
                    variant="outlined"
                    className={`${LocalStyle.primaryButton}`}
                    onClick={handleBackButtonClick}
                  >
                    Back
                  </Button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} className={LocalStyle.buttonStyle}>
            <Row>
              <Col xs={6} sm={6} md={6} lg={6}>
                <Button
                  sx={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    },
                  }}
                  variant="outlined"
                  className={`${LocalStyle.primaryButton}`}
                  onClick={handleClickClearBtn}
                >
                  {!isEdit ? "Clear form" : "Cancel"}
                </Button>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6}>
                <Button
                  disabled={
                    fpoName &&
                    riName &&
                    geography?.state &&
                    geography?.district &&
                    geography?.block &&
                    geography?.village &&
                    (order || procurements) &&
                    presidentName &&
                    presidentNumber?.length >= 10 &&
                    presidentGender &&
                    !presidentNumberErrorMessage &&
                    !ceoNumberErrorMessage &&
                    incorpCertificate
                      ? false
                      : true
                  }
                  variant="contained"
                  className={LocalStyle.saveBtn}
                  onClick={() => {
                    handleFormSubmit();
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FPOForm;
