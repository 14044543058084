import React from "react";

// import upload_img from "../../Assets/Img/Farmstack V2.0/upload.svg";
export default function CustomUploadFile(props) {
  return (
    <div
      className="fileupload"
      style={{
        maxWidth: "544px",
        maxHeight: "298px",
        border: "1px dashed #3A70CA",
        cursor: "pointer",
        borderRadius: "20px",
        // background: "#F4F6F8",
      }}
      id={`file-upload-drag-and-drop-${props.index}`}
    >
      <p className="header" style={{ paddingTop: "24px" }}>
        {props.uploadtitle}
      </p>
      <div style={{ textAlign: "center" }}>
        {/* <img src={upload_img} />{" "} */}
      </div>
      <p style={{ color: "#A3B0B8" }}>
        {props.texts} {props.maxSize}.
      </p>
      {/* <p style={{ color: "#A3B0B8" }}>{props.uploades}</p> */}
    </div>
  );
}
