import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Login } from "../pages/Login/Login";
import FPOManagement from "../pages/Home/Index";
import DashBoard from "../components/Navbar/Dashboard";
import { getRoleLocal } from "../utils/common/common";
import Navbar from "../components/Navbar/Navbar";
import AddFPO from "../pages/AddFPO/AddFPO";

const UserRoutes = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const userRole = getRoleLocal();
  console.log("🚀 ~ UserRoutes ~ userRole:", getRoleLocal());

  return (
    <>
    {currentPath !== "/login" && userRole && <Navbar userRole={userRole} />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/FPOManagement" element={<FPOManagement />} />
        <Route path="/addFPO" element={<AddFPO />} />
        <Route path="/editFPO" element={<AddFPO isEdit={true} />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/login" />} />
        <Route path="/efarmDashboard" element={<DashBoard />} />
      </Routes>
    </>
  );
};

export default UserRoutes;
