import React from "react";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import LocalStyle from "./CustomDeletePopper.module.css";
import { Button, Typography } from "@mui/material";

const CustomDeletePopper = (props) => {
  const {
    anchorEl,
    handleDelete,
    id,
    open,
    closePopper,
  } = props;
  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      transition
      placement="bottom-end"
      modifiers={[
        {
          name: "flip",
          enabled: false,
        },
        {
          name: "preventOverflow",
          enabled: false,
        },
        {
          name: "hide",
          enabled: false,
        },
        {
          name: "positionFixed",
          enabled: true,
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box
            className={LocalStyle.popperContainer}
            sx={{
              border: 1,
              p: 1,
              bgcolor: "background.paper",
              transform: "translateX(19px)",
              minWidth: 255,
            }}
          >
            <Typography
              variant="subtitle1"
              className={`$${LocalStyle.popperMessage}`}
              style={{ fontFamily: "Montserrat" }}
            >
              Are you sure, you want to delete this certificate?
            </Typography>
            <div className={LocalStyle.popperButtonContainer}>
              <Button
                variant="outlined"
                className={`${LocalStyle.noButton}`}
                onClick={(e) => closePopper(e)}
                data-testid="closepopper"
              >
                No
              </Button>
              <Button
                variant="outlined"
                className={`${LocalStyle.yesButton}`}
                onClick={(e) => handleDelete(e)}
                data-testid="deletepopper"
              >
                Yes
              </Button>
            </div>
          </Box>
        </Fade>
      )}
    </Popper>
  );
};

export default CustomDeletePopper;
