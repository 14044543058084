import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import UrlConstants from "../../utils/constants/urlConstants";
import HTTPService from "../../services/HTTPService";
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { EFarmContext } from "../../Contexts/eFarmContext";
import LocalStyle from "./MapVillages.module.css";
import left_arrow from "../../assets/images/left_arrow.svg";
import right_arrow from "../../assets/images/right_arrow.svg";
import { getTokenLocal } from "../../utils/common/common";
import { useNavigate } from "react-router-dom";

const inputStyle = { marginBottom: "10px", width: "100%", maxWidth: "545px" };

const MapVillages = (props) => {
  const navigate = useNavigate();
  const { selectedFPO } = props;
  const [selectItems, setSelectItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedVillages, setSelectedVillages] = useState([]);
  const [selectedVillagesItems, setSelectedVillagesItems] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [flewList, setFlewList] = useState([]);
  const [SelectedFlew, setSelectedFlew] = useState("");
  const { callToast, callLoader } = useContext(EFarmContext);

  const getFLEWList = () => {
    callLoader(true);
    HTTPService(
      "GET",
      UrlConstants.base_url +
        UrlConstants.get_flew_list +
        "?fpc_id=" +
        selectedFPO,
      "",
      false,
      true,
      getTokenLocal()
    )
      .then((response) => {
        console.log(response);
        setFlewList(response?.data);
        setIsSelected(false);
        setSelectedItems([]);
        setSelectedVillages([]);
        setSelectedVillagesItems([]);
        setSelectedFlew("");
        callLoader(false);
      })
      .catch((err) => {
        callLoader(false);
        console.log(err);
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          navigate("/login");
          callToast("Session expired, please login again!", "success", true);
        } else if (
          err?.response?.status === 400 &&
          err?.response?.data?.error
        ) {
          callToast(err?.response?.data?.error, "error", true);
        } else {
          callToast("Something went wrong!", "error", true);
        }
      });
  };

  const getVillageListFLEW = () => {
    callLoader(true);
    HTTPService(
      "GET",
      UrlConstants.base_url +
        UrlConstants.get_flw_village_list +
        "?fpc_id=" +
        selectedFPO,
      "",
      false,
      true,
      getTokenLocal()
    )
      .then((response) => {
        console.log(response);
        setSelectItems(response?.data);
        setSelectedItems([]);
        setSelectedVillages([]);
        setSelectedVillagesItems([]);
        setIsSelected(false);
        callLoader(false);
      })
      .catch((err) => {
        console.log(err);
        callToast("Something went wrong!", "error", true);
        callLoader(false);
      });
  };

  const handleToggle = (item) => () => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems?.includes(item)
        ? prevSelectedItems?.filter((i) => i !== item)
        : [...prevSelectedItems, item]
    );
    setIsSelected(false);
  };

  const handleToggleLeft = (item) => () => {
    setSelectedVillagesItems((prevSelectedVillagesItems) => {
      const newSelectedVillagesItems = prevSelectedVillagesItems?.includes(item)
        ? prevSelectedVillagesItems?.filter((i) => i !== item)
        : [...prevSelectedVillagesItems, item];
      setIsSelected(newSelectedVillagesItems?.length > 0);
      return newSelectedVillagesItems;
    });
  };
  const handleMoveSelectedVillage = () => {
    setSelectedVillages((prevSelectedVillages) => [
      ...prevSelectedVillages,
      ...selectedItems,
    ]);
    setSelectItems((prevItems) =>
      prevItems?.filter((item) => !selectedItems?.includes(item))
    );
    setSelectedItems([]); // Clear selected items after moving
    setIsSelected(false);
  };

  const handleMoveBackSelectedVillage = () => {
    setSelectItems((prevItems) => [...prevItems, ...selectedVillagesItems]);
    setSelectedVillages((prevSelectedVillages) =>
      prevSelectedVillages.filter(
        (item) => !selectedVillagesItems?.includes(item)
      )
    );
    setSelectedVillagesItems([]); // Clear selected items after moving
    setIsSelected(false);
  };
  const handleSubmit = async () => {
    callLoader(true);
    let data = {
      selected_flw: SelectedFlew,
      fpc: selectedFPO,
      villages: JSON?.stringify(
        selectedVillages?.map((village) => village?.filter_id)
      ),
    };
    let url = UrlConstants.base_url + UrlConstants.map_flw_and_villages;
    return await HTTPService("PUT", url, data, true, true, getTokenLocal())
      .then((response) => {
        console.log(response);
        callLoader(false);
        callToast(response?.data?.message, "success", true);
        getFLEWList();
        getVillageListFLEW();
        setSelectedFlew("");
      })
      .catch(async (err) => {
        console.log(err);
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          navigate("/login");
          callToast("Session expired, please login again!", "error", true);
        } else if (err?.response?.status === 400) {
          callToast(err?.response?.data?.error, "error", true);
        } else {
          callToast("Something went wrong!", "error", true);
        }
        callLoader(false);
      });
  };
  useEffect(() => {
    getFLEWList();
  }, [selectedFPO]);

  useEffect(() => {
    getVillageListFLEW();
  }, [selectedFPO]);

  return (
    <>
      {flewList.length !== 0 ? (
        <>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginBottom: "20px" }}
            >
              <Typography className={LocalStyle.subHead}>
                {"Select FLEW"}
              </Typography>
            </Col>
          </Row>
          <Row style={{ marginBottom: "30px" }}>
            <Col xs={6} sm={6} md={6} lg={6}>
              {" "}
              <FormControl
                className={LocalStyle.textField}
                variant="outlined"
                fullWidth
                required
                style={inputStyle}
              >
                <InputLabel
                  id="demo-multiple-name-label"
                  label="demo-multiple-name-label"
                  variant="outlined"
                >
                  Select FLEW
                </InputLabel>
                {
                  <Select
                    style={{
                      textAlign: "left",
                    }}
                    labelId="FLEW Data"
                    id="FLEW Data"
                    label=" Select FLEW "
                    fullWidth
                    required
                    value={SelectedFlew}
                    onChange={(e) => {
                      setSelectedFlew(e.target.value);
                      setSelectedItems([]);
                      setSelectedVillages([]);
                      setSelectedVillagesItems([]);
                      getVillageListFLEW();
                    }}
                    variant="outlined"
                    addlabel="demo-multiple-name-label"
                  >
                    {flewList?.map((list, index) => {
                      return (
                        <MenuItem
                          className="fontFamily"
                          key={index}
                          value={list?.filter_id}
                        >
                          {list?.first_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
              </FormControl>
            </Col>
          </Row>
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={6} sm={6} md={6} lg={6}>
              <Typography className={LocalStyle.subHead}>
                {"List of FPO villages"}
              </Typography>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <Typography
                className={LocalStyle.subHead}
                style={{ marginLeft: "25px" }}
              >
                {"Selected villages"}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col xs={5} sm={5} md={5} lg={5}>
              <Box
                sx={{
                  width: "517px",
                  height: "298px",
                  gap: "0px",
                  borderRadius: "10px",
                  border: "1px solid #3A70CA",
                  overflow: "auto",
                  paddingTop: "12px",
                }}
              >
                {selectItems?.length !== 0 ? (
                  <>
                    {selectItems?.map((item, index) => (
                      <MenuItem
                        key={index}
                        disabled={
                          !SelectedFlew || selectedVillages.length >= 10
                        }
                        onClick={handleToggle(item)}
                        selected={selectedItems.includes(item)}
                        sx={{
                          backgroundColor: "transparent",
                          color: "#212B36",
                          borderRadius: "4px",
                          borderBottom: "1px solid #E2E2E2",
                          marginBottom: "4px",
                          cursor: "pointer",
                          "&.Mui-selected": {
                            backgroundColor: "#d6f4f9 !important",
                          },
                        }}
                      >
                        <Typography variant="body1">
                          {item?.village_name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </>
                ) : (
                  <Typography className={LocalStyle.SelectFpo}>
                    No village is available for this FPO
                  </Typography>
                )}
              </Box>
            </Col>
            <Col
              xs={2}
              sm={2}
              md={2}
              lg={2}
              style={{ width: "20px", marginLeft: "60px" }}
            >
              <img
                id="document-logo"
                height="34px"
                width="34px"
                src={isSelected ? left_arrow : right_arrow}
                style={{ cursor: "pointer" }}
                alt="arrow button"
                onClick={
                  isSelected
                    ? handleMoveBackSelectedVillage
                    : handleMoveSelectedVillage
                }
              />
            </Col>
            <Col
              xs={5}
              sm={5}
              md={5}
              lg={5}
              style={{ marginLeft: "auto", marginRight: "70px" }}
            >
              {" "}
              <Box
                sx={{
                  width: "517px",
                  height: "298px",
                  gap: "0px",
                  borderRadius: "10px",
                  border: "1px solid #3A70CA",
                  overflow: "auto",
                  paddingTop: "12px",
                }}
              >
                {selectedVillages?.length > 0 ? (
                  <>
                    {selectedVillages?.map((item, index) => (
                      <MenuItem
                        key={index}
                        onClick={handleToggleLeft(item)}
                        selected={selectedVillagesItems.includes(item)}
                        sx={{
                          backgroundColor: "transparent",
                          color: "#212B36",
                          borderRadius: "4px",
                          borderBottom: "1px solid #E2E2E2",
                          marginBottom: "4px",
                          cursor: "pointer",
                          "&.Mui-selected": {
                            backgroundColor: "#d6f4f9 !important",
                          },
                        }}
                      >
                        <Typography variant="body1">
                          {item?.village_name}
                        </Typography>
                      </MenuItem>
                    ))}{" "}
                  </>
                ) : (
                  <Typography className={LocalStyle.SelectFpo}>
                    Select a village to Map FLEW
                  </Typography>
                )}
              </Box>
            </Col>
          </Row>
          <hr style={{ marginTop: "60px", width: "1130px" }} />
          <Row>
            <Col xs={6} sm={6} md={6} lg={6}></Col>
            <Col xs={6} sm={6} md={6} lg={6} className={LocalStyle.buttonStyle}>
              <Button
                variant="contained"
                className={`btn ${LocalStyle.saveBtn}`}
                onClick={() => handleSubmit()}
                disabled={
                  SelectedFlew && selectedVillages.length > 0 ? false : true
                }
              >
                Submit
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <Typography className={LocalStyle.SelectFpo}>
          No FLEW and villages found for the selected FPO. Please choose
          another.
        </Typography>
      )}
    </>
  );
};
export default MapVillages;
