// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_selectBox__NHiEp {
  width: 100% !important;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
}
.style_selectBox__NHiEp > p {
  margin-right: 20px;
  font-size: larger;
}
.style_saveBtn__3ZGjP {
  width: 200px;
  font-family: "Montserrat" !important;
}
.style_title__qBWtF {
  font-weight: 600;
  font-size: 34px;
  text-align: center;
}
.style_browseStyle__tsc6I {
  font-family: "Montserrat" !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  letter-spacing: 0px !important;
  color: #000000 !important;
  justify-content: center !important;
}
.style_browseBtn__FoV57 {
  max-width: 192px !important;
  max-height: 48px !important;
  padding: 11px, 22px, 11px, 22px !important;
  border-radius: 6px !important;
  border: 1px solid #00ab55 !important;
  gap: 8px !important;
  color: #00ab55 !important;
  font-family: "Montserrat" !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  letter-spacing: 0px !important;
  justify-content: center !important;
}
.style_browseBtn__FoV57:disabled {
  color: gray !important;
  border: 1px solid gray !important;
  cursor: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/FPOForm/style.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,oCAAoC;AACtC;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,oCAAoC;EACpC,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,8BAA8B;EAC9B,yBAAyB;EACzB,kCAAkC;AACpC;AACA;EACE,2BAA2B;EAC3B,2BAA2B;EAC3B,0CAA0C;EAC1C,6BAA6B;EAC7B,oCAAoC;EACpC,mBAAmB;EACnB,yBAAyB;EACzB,oCAAoC;EACpC,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,8BAA8B;EAC9B,kCAAkC;AACpC;AACA;EACE,sBAAsB;EACtB,iCAAiC;EACjC,YAAY;AACd","sourcesContent":[".selectBox {\n  width: 100% !important;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  display: block;\n}\n.selectBox > p {\n  margin-right: 20px;\n  font-size: larger;\n}\n.saveBtn {\n  width: 200px;\n  font-family: \"Montserrat\" !important;\n}\n.title {\n  font-weight: 600;\n  font-size: 34px;\n  text-align: center;\n}\n.browseStyle {\n  font-family: \"Montserrat\" !important;\n  font-size: 18px !important;\n  font-weight: 600 !important;\n  line-height: 30px !important;\n  letter-spacing: 0px !important;\n  color: #000000 !important;\n  justify-content: center !important;\n}\n.browseBtn {\n  max-width: 192px !important;\n  max-height: 48px !important;\n  padding: 11px, 22px, 11px, 22px !important;\n  border-radius: 6px !important;\n  border: 1px solid #00ab55 !important;\n  gap: 8px !important;\n  color: #00ab55 !important;\n  font-family: \"Montserrat\" !important;\n  font-size: 15px !important;\n  font-weight: 700 !important;\n  line-height: 26px !important;\n  letter-spacing: 0px !important;\n  justify-content: center !important;\n}\n.browseBtn:disabled {\n  color: gray !important;\n  border: 1px solid gray !important;\n  cursor: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectBox": `style_selectBox__NHiEp`,
	"saveBtn": `style_saveBtn__3ZGjP`,
	"title": `style_title__qBWtF`,
	"browseStyle": `style_browseStyle__tsc6I`,
	"browseBtn": `style_browseBtn__FoV57`
};
export default ___CSS_LOADER_EXPORT___;
