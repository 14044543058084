// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MapVillages_subHead__v2-4N {
  font-family: "Montserrat" !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 24.38px !important;
  color: #000000 !important;
  margin-top: 30px !important;
  width: max-content !important;
}
.MapVillages_saveBtn__IweIr {
  width: 200px;
  text-transform: capitalize !important;
  font-family: "Montserrat" !important;
}
.MapVillages_buttonStyle__4dKom {
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
  margin-left: auto !important;
  margin-right: 45px !important;
  margin-top: 50px !important;
  margin-bottom: 100px !important;
}
.MapVillages_SelectFpo__RkqLl {
  font-family: "Montserrat" !important;
  color: #8f8f8f !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  letter-spacing: 0px !important;
  margin: 100px 50px 100px 50px !important;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/MapVillages/MapVillages.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,0BAA0B;EAC1B,2BAA2B;EAC3B,+BAA+B;EAC/B,yBAAyB;EACzB,2BAA2B;EAC3B,6BAA6B;AAC/B;AACA;EACE,YAAY;EACZ,qCAAqC;EACrC,oCAAoC;AACtC;AACA;EACE,sCAAiC;EAAjC,iCAAiC;EACjC,4BAA4B;EAC5B,6BAA6B;EAC7B,2BAA2B;EAC3B,+BAA+B;AACjC;AACA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,8BAA8B;EAC9B,wCAAwC;EACxC,kBAAkB;AACpB","sourcesContent":[".subHead {\n  font-family: \"Montserrat\" !important;\n  font-size: 20px !important;\n  font-weight: 600 !important;\n  line-height: 24.38px !important;\n  color: #000000 !important;\n  margin-top: 30px !important;\n  width: max-content !important;\n}\n.saveBtn {\n  width: 200px;\n  text-transform: capitalize !important;\n  font-family: \"Montserrat\" !important;\n}\n.buttonStyle {\n  max-width: fit-content !important;\n  margin-left: auto !important;\n  margin-right: 45px !important;\n  margin-top: 50px !important;\n  margin-bottom: 100px !important;\n}\n.SelectFpo {\n  font-family: \"Montserrat\" !important;\n  color: #8f8f8f !important;\n  font-size: 16px !important;\n  font-weight: 600 !important;\n  line-height: 22px !important;\n  letter-spacing: 0px !important;\n  margin: 100px 50px 100px 50px !important;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subHead": `MapVillages_subHead__v2-4N`,
	"saveBtn": `MapVillages_saveBtn__IweIr`,
	"buttonStyle": `MapVillages_buttonStyle__4dKom`,
	"SelectFpo": `MapVillages_SelectFpo__RkqLl`
};
export default ___CSS_LOADER_EXPORT___;
