const LocalStorageConstants = {
  KEYS: {
    JWTToken: "JWTToken",
    user: "user",
    role: "role",
    roleName: "role_Name",
    org_id: "org_id",
    user_map: "user_map",
    error: "error",
    userId: "userId",
    sessionId: "sessionId",
    firstName: "first_name",
    lastName: "last_name",
    orgId: "org_id",
    orgName: "org_name",
    fpcId: "fpc_id",
    fpcName: "fpc_name",
  },
  ROLES: {
    super_user: "super_user",
    admin_user: "admin_user",
    fpc_admin: "fpc_admin",
    fpc_chairman: "fpc_chairman",
    fpc_user: "fpc_user",
    ri_user: "ri_user",
    ri_admin: "ri_admin",
    flw: "flw",
    PROGRAM_COORDINATOR: "dg_user",
    FPC_CEO: "fpc_ceo",
    FPC_PRESIDENT: "fpc_president",
    FPC_BOARD_MEMBER: "fpc_board_member",
  },
};

export default LocalStorageConstants;
