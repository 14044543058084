import { useEffect, useState, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "../../pages/uploadFile/uploadFile.css";
import {
  flushLocalstorage,
  getRoleLocal,
  getUserLocal,
  setRoleLocal,
  getTokenLocal,
} from "../../utils/common/common";
import { useNavigate, useLocation } from "react-router-dom";
import DGlogo from "../../assets/images/Digital_Green_Primary 1.svg";
import HTTPService from "../../services/HTTPService";
import UrlConstants from "../../utils/constants/urlConstants";
import { EFarmContext } from "../../Contexts/eFarmContext";
import DashBoard from "./Dashboard";
const Navbar = () => {
  const navigate = useNavigate();
  const loaction = useLocation();
  const currentPath = loaction.pathname;
  console.log("🚀 ~ Navbar ~ loaction:", currentPath);
  const { callToast, callLoader } = useContext(EFarmContext);

  const [userRole, setUserRole] = useState(getRoleLocal());
  const org_id = localStorage.getItem("org_id");

  const handleDashboardClick = () => {
    navigate("/efarmDashboard");
  };

  const hanldeLogOut = () => {
    console.log("function is calling");
    let data = {
      source: "web",
    };
    if (org_id) {
      data["org_id"] = org_id;
    }
    //get list of states
    HTTPService(
      "POST",
      UrlConstants.base_url + UrlConstants.log_out,
      data,
      true,
      true,
      getTokenLocal()
    )
      .then((response) => {
        if (response?.status === 200) {
          flushLocalstorage();
          setUserRole("");
          navigate("/login");
          setRoleLocal("");
          callToast("Successfully logged out", "success", true);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e?.response?.status === 401 || e?.response?.status === 403) {
          navigate("/login");
          flushLocalstorage();
          callToast("Session expired, please login again!", "success", true);
        } else {
          callToast("something went wrong", "error", true);
        }
      });
  };
  useEffect(() => {
    if (!getUserLocal()) {
      console.log("🚀 ~ useEffect ~ getUserLocal():", getUserLocal());
      navigate("./login");
      flushLocalstorage();
    }
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1, boxShadow: "none" }}>
        <AppBar className="upload-file-navbar" position="static">
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: "flex" }}
              style={{ marginLeft: "124px" }}
            >
              <img src={DGlogo} alt="DG LOGO" />
            </Typography>
            {currentPath == "/efarmDashboard" ? (
              <Button
                onClick={() => navigate("/FPOManagement")}
                className="dashBoard"
              >
                Back
              </Button>
            ) : (
              <Button onClick={handleDashboardClick} className="dashBoard">
                Dashboard
              </Button>
            )}
            <Button onClick={hanldeLogOut} className="logOutBtnScreen">
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default Navbar;
