// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fileupload {
  /* background: #ffffff;
  border: 1px dashed #d8af28;
  border-radius: 2px;
  margin-top: 0;
  /*margin-left: 35%;*/
  /*width: 220%;*/
  /* height: 218px;
  align-items: center; */ 
}
.header {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #3d4a52;
  padding-top: 2%;
}
.addFile {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #3d4a52;
  float: left;
  /*margin-left: 20%;*/
  margin-top: 3%;
  margin-bottom: 3%;
}
.fileUpload {
  width: 100% !important;
}

.fileUpload.is-disabled {
  border: none !important;
  cursor: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/uploadFile/customfileupload.css"],"names":[],"mappings":"AAAA;EACE;;;;sBAIoB;EACpB,eAAe;EACf;wBACsB;AACxB;AACA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB;AACA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,WAAW;EACX,oBAAoB;EACpB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;AACzB","sourcesContent":[".fileupload {\n  /* background: #ffffff;\n  border: 1px dashed #d8af28;\n  border-radius: 2px;\n  margin-top: 0;\n  /*margin-left: 35%;*/\n  /*width: 220%;*/\n  /* height: 218px;\n  align-items: center; */ \n}\n.header {\n  font-family: \"Open Sans\";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 19px;\n  text-align: center;\n  color: #3d4a52;\n  padding-top: 2%;\n}\n.addFile {\n  font-family: \"Open Sans\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 20px;\n  color: #3d4a52;\n  float: left;\n  /*margin-left: 20%;*/\n  margin-top: 3%;\n  margin-bottom: 3%;\n}\n.fileUpload {\n  width: 100% !important;\n}\n\n.fileUpload.is-disabled {\n  border: none !important;\n  cursor: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
